import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { MdAdd } from 'react-icons/md';
import { Card } from '@/components/card';
import { DefaultTypography } from '@/components/typography';
import { Flexbox } from '@/components/flex';
import { HSpace, VSpace } from '@/components/spacing';
import {
  EditorDeclarativeBlock,
  ExperienceVariant,
} from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { formatEnum } from '@/utils/types';
import { Tooltip } from '@/components/tooltip';
import { getAllowedPrimaryGoals } from '@/webapi/models';
import { GoalsModal } from '@/features/editor/widgets/shared/modals/goals-modal';
import { MAX_SELECTED_GOALS } from '@/features/editor/widgets/shared/modals/use-goals-modal';
import {
  AllocationWrapper,
  formatAllocation,
} from '@/features/editor/widgets/targeting/shared';
import { ThemeVariantName } from '@/features/editor/widgets/theme-test/theme-variant-name';
import { AddVariantBtn } from '@/features/editor/widgets/theme-test/add-variant-btn';
import { OriginalTheme } from '@/features/editor/widgets/theme-test/original-theme';
import { ThemesModal } from '@/features/editor/widgets/shared/modals/audience/themes-modal';
import { useThemesModal } from '@/features/editor/widgets/shared/targeting-controls/use-themes-modal';
import { useGoals } from '@/features/editor/widgets/theme-test/use-goals';
import { GaEvents } from '@/features/editor/widgets/theme-test/ga-events';
import { ThemeChange } from '@/pkg/sdk';

interface ThemesAllocationCardProps {
  originalChange?: EditorDeclarativeBlock;
}

export function ThemesAllocation({
  originalChange,
}: ThemesAllocationCardProps) {
  const { experienceState, devicePreview } = useContext(EditorContext);
  const { currentExperience, upsertEditorChange } = experienceState;
  const {
    editorState: { device },
  } = devicePreview;
  const { variants } = currentExperience;
  const fromRef = useRef(null);

  const {
    themes,
    isVisible: isThemesModalVisible,
    setIsVisible: setIsThemesModalVisible,
    onClick: onShowThemesModal,
  } = useThemesModal();

  const onThemeSelect = (themeId: number) => {
    const change = produce(originalChange, (draft: EditorDeclarativeBlock) => {
      draft.block.kind = `themeTest`;
      draft.block.selector = `div`;
      draft.block.value = {
        targetThemeId: themeId,
      } as ThemeChange;
    });
    upsertEditorChange(change, false, false, themeId);
  };

  return (
    <>
      <ThemesModal
        header="please select variation theme"
        fromRef={fromRef}
        isVisible={isThemesModalVisible}
        setIsVisible={setIsThemesModalVisible}
        onSaveCb={onThemeSelect}
        disableMainTheme
        themes={themes}
      />

      <Wrapper device={device} ref={fromRef}>
        <Header device={device} />
        <VSpace value={2} />
        <OriginalTheme />
        {variants?.map((variant) => (
          <div key={variant.id}>
            <VSpace value={2} />
            <ThemeVariation
              device={device}
              variant={variant}
              onShowThemesModal={() => {
                experienceState.selectActiveVariant(variant.id);
                const targetThemeId = (
                  variant?.changes?.[0]?.block?.value as ThemeChange
                )?.targetThemeId;
                if (targetThemeId) {
                  document.dispatchEvent(
                    new CustomEvent(`SelectTheme`, {
                      detail: { targetThemeId },
                    }),
                  );
                }
                onShowThemesModal();
              }}
            />
          </div>
        ))}
        <AddVariantBtn />
        <GoalsSelect device={device} ref={fromRef} />
        <GaEvents />
      </Wrapper>
    </>
  );
}

function Header({ device }) {
  return (
    <Flexbox width="100%" direction="row" justify="space-between">
      <HeaderText device={device}>Set Variants</HeaderText>
      <HeaderText device={device} style={{ marginRight: `1.3rem` }}>
        Allocation
      </HeaderText>
    </Flexbox>
  );
}

type VariationProps = {
  variant: ExperienceVariant;
  device: DeviceType;
  _changeAllocation?: (variant: ExperienceVariant) => void;
  publishedChance?: number;
  _variants?: ExperienceVariant[];
  disableNameEdit?: boolean;
  bg?: string;
  onShowThemesModal?: () => void;
};

export function ThemeVariation({
  variant,
  device,
  _changeAllocation,
  publishedChance,
  _variants,
  disableNameEdit,
  bg,
  onShowThemesModal,
}: VariationProps) {
  const edtCtx = useContext(EditorContext);
  const variants =
    _variants || edtCtx?.experienceState?.currentExperience?.variants;
  const changeAllocation =
    _changeAllocation || edtCtx?.experienceState?.changeAllocation;

  const onTextChanged = (ev) => {
    const maxChance = getMaxChance(variants, variant);
    let value = parseAllocation(ev.target.value);
    if (value > maxChance) {
      value = maxChance;
    } else if (value < 0) {
      value = 0;
    }
    const updated = produce(variant, (draft) => {
      draft.chance = value;
      if (typeof _changeAllocation !== `undefined`) {
        draft.publishedChance = value;
      }
    });
    ev.target.value = formatAllocation(value);
    changeAllocation(updated);
  };
  return (
    <AllocationWrapper device={device} bg={bg}>
      <ThemeVariantName
        variant={variant}
        bg={bg}
        disableNameEdit={disableNameEdit}
        onShowThemesModal={onShowThemesModal}
      />
      <AllocationInput
        device={device}
        onFocus={(e) => e.target.select()}
        onChange={onTextChanged}
        defaultValue={formatAllocation(
          publishedChance || (variant.chance ? variant.chance : 0),
        )}
      />
      <HSpace />
    </AllocationWrapper>
  );
}

function parseAllocation(percent: string): number {
  try {
    return parseInt(percent.replace(`%`, ``), 10);
  } catch (ignored) {
    return 0;
  }
}

const Wrapper = styled(Card)`
  && {
    min-width: 390px;
    width: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `100%` : `100%`};
    padding: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `2rem` : `3rem`};

    cursor: default;

    :hover {
      background: white;
    }

    :active {
      background: white;
    }
  }
`;

const HeaderText = styled(DefaultTypography)`
  && {
    display: flex;
    color: black;
    font-size: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `1.3rem` : `1.3rem`};
    font-weight: 600;
  }
`;

const AllocationInput = styled.input`
  pointer-events: auto;
  outline: none;
  height: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `4.5rem` : `4.5rem`};
  width: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `7rem` : `7rem`};
  border: 1.5px solid #c7c8cf;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Inter, serif;
  font-weight: 600;
  color: #5b656e;
  font-size: ${({ device }: { device: DeviceType }) =>
    device === DeviceType.Desktop ? `1.5rem` : `1.5rem`};
`;

export const GoalsWrapper = styled.ul`
  && {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    max-width: 56.8rem;
    list-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    && > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

type P = { device: DeviceType; focused?: boolean };

export const GoalChip = styled.li`
  && {
    cursor: pointer;
    width: auto;
    opacity: ${({ focused }: P) => (focused ? `1` : `0.5`)};
    padding: 0.8rem 1.2rem;
    border: 1.5px solid #f2f4f6;
    border-radius: 1.8rem;
    user-select: none;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Inter, serif;
    color: #58606c;
    background: #f2f4f6;
    transition: opacity 0.2s ease-out;

    :hover {
      opacity: 0.8;
    }

    :active {
      opacity: 0.6;
    }
  }
`;

export const PrimaryGoalChip = styled(GoalChip)`
  && {
    border: 1.5px solid ${({ focused }: P) => (focused ? `#0086FF` : `#f2f4f6`)};
    color: ${({ focused }: P) => (focused ? `#0086FF` : `#58606c`)};
  }
`;

const GoalChipAddMore = styled(GoalChip)`
  && {
    padding: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.3rem;
    min-width: 3.3rem;
    background: #e3e9ee;
    color: #adb6be;
    border-radius: 50%;
  }
`;

function getMaxChance(
  variants: ExperienceVariant[],
  variant: ExperienceVariant,
) {
  if (variants.length > 1) {
    return 100;
  }
  return (
    100 -
    variants
      .filter((v) => v.id !== variant.id)
      .reduce((p, c) => p + c.chance, 0)
  );
}

function GoalsSelect({
  device,
  ref,
}: {
  device: DeviceType;
  ref: React.MutableRefObject<null>;
}) {
  const { experienceState } = useContext(EditorContext);
  const { currentExperience, setPrimaryGoal } = experienceState;
  const { isGoalsVisible, setIsGoalsVisible, onGoalsClick } = useGoals(false);
  const fromRef = useRef();

  return (
    <>
      <VSpace value={2} />
      <GoalsModal
        fromRef={fromRef}
        isVisible={isGoalsVisible}
        setIsVisible={setIsGoalsVisible}
      />
      <Flexbox overflow="scroll" ref={fromRef}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <HeaderText device={device}>
          Set Goals
          <Tooltip
            text="Choose which metrics you want to see in A/B validation reports"
            title="Set Goals"
          />
        </HeaderText>
        <VSpace value={2} />

        <GoalsWrapper ref={ref} onClick={onGoalsClick}>
          {currentExperience.goals.map((item) => (
            <GoalChip key={item} device={device} focused>
              {formatEnum(item, true)}
            </GoalChip>
          ))}
          {currentExperience.goals.length < MAX_SELECTED_GOALS && (
            <GoalChipAddMore>
              <MdAdd size={20} />
            </GoalChipAddMore>
          )}
        </GoalsWrapper>
      </Flexbox>
      {currentExperience.goals.length > 1 && (
        <Flexbox overflow="scroll">
          <VSpace value={2} />
          <HeaderText device={device}>
            Set Primary Goal
            <Tooltip
              text="Choose which metric you want to emphasize in A/B validation reports"
              title="Set Primary Goal"
            />
          </HeaderText>
          <VSpace value={2} />
          <GoalsWrapper>
            {getAllowedPrimaryGoals(currentExperience.goals)?.map((item) => (
              <PrimaryGoalChip
                key={item}
                device={device}
                focused={item === currentExperience?.primaryGoal}
                onClick={() => setPrimaryGoal(item)}
              >
                {formatEnum(item, true)}
              </PrimaryGoalChip>
            ))}
          </GoalsWrapper>
        </Flexbox>
      )}
    </>
  );
}
