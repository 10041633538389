import React from 'react';

export const MOUNT_POINT_SINGLE = () => (
  <svg
    width="30px"
    height="36px"
    viewBox="0 0 30 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="30" height="8.35714286" rx="1.28" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="merch-copy-5"
        transform="translate(-933, -208)"
        fill="#BBF4F7"
        stroke="#94BDBF"
      >
        <g id="Group" transform="translate(933, 208)">
          <rect
            id="Rectangle"
            strokeWidth="0.64"
            x="0.32"
            y="11.8914286"
            width="29.36"
            height="12.2171429"
            rx="1.28"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const NO_MOUNT_POINTS_SVG = () => (
  <svg
    width="30px"
    height="36px"
    viewBox="0 0 30 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <rect id="path-1" x="0" y="0" width="30" height="8.35714286" rx="1.28" />
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="30"
        height="8.35714286"
        fill="white"
      >
        <use xlinkHref="#path-1" />
      </mask>
      <rect
        id="path-3"
        x="0"
        y="27.6428571"
        width="30"
        height="8.35714286"
        rx="1.28"
      />
      <mask
        id="mask-4"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="30"
        height="8.35714286"
        fill="white"
      >
        <use xlinkHref="#path-3" />
      </mask>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="merch-copy-5"
        transform="translate(-887, -208)"
        fill="#F7BBC5"
        stroke="#BF94A7"
      >
        <g id="Group" transform="translate(887, 208)">
          <rect
            id="Rectangle-Copy-5"
            strokeWidth="0.64"
            x="0.32"
            y="11.8914286"
            width="29.36"
            height="12.2171429"
            rx="1.28"
          />
          <use
            id="Rectangle-Copy-4"
            mask="url(#mask-2)"
            strokeWidth="1.28"
            opacity="0.5"
            strokeDasharray="2.56"
            xlinkHref="#path-1"
          />
          <use
            id="Rectangle-Copy-3"
            mask="url(#mask-4)"
            strokeWidth="1.28"
            opacity="0.3"
            strokeDasharray="2.56"
            xlinkHref="#path-3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const HAS_MOUNT_POINTS_SVG = () => (
  <svg
    width="30px"
    height="36px"
    viewBox="0 0 30 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="30" height="8.35714286" rx="1.28" />
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="30"
        height="8.35714286"
        fill="white"
      >
        <use xlinkHref="#path-1" />
      </mask>
      <rect
        id="path-3"
        x="0"
        y="27.6428571"
        width="30"
        height="8.35714286"
        rx="1.28"
      />
      <mask
        id="mask-4"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="30"
        height="8.35714286"
        fill="white"
      >
        <use xlinkHref="#path-3" />
      </mask>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="merch-copy-5"
        transform="translate(-933, -208)"
        fill="#BBF4F7"
        stroke="#94BDBF"
      >
        <g id="Group" transform="translate(933, 208)">
          <rect
            id="Rectangle"
            strokeWidth="0.64"
            x="0.32"
            y="11.8914286"
            width="29.36"
            height="12.2171429"
            rx="1.28"
          />
          <use
            id="Rectangle-Copy"
            mask="url(#mask-2)"
            strokeWidth="1.28"
            opacity="0.5"
            strokeDasharray="2.56"
            xlinkHref="#path-1"
          />
          <use
            id="Rectangle-Copy-2"
            mask="url(#mask-4)"
            strokeWidth="1.28"
            opacity="0.3"
            strokeDasharray="2.56"
            xlinkHref="#path-3"
          />
        </g>
      </g>
    </g>
  </svg>
);
