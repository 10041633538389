import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAsyncEffect from 'use-async-effect';
import { StringParam, useQueryParam } from 'use-query-params';
import { Experience, ExperienceStatus } from '@/webapi/use-experience-api';
import { ExperienceTile } from '@/features/dashboard/experiences/tile';
import { VSpace } from '@/components/spacing';
import { detailsRoute, editorRoute } from '@/webapi/pages';
import { Pagination } from '@/components/pagination';
import { ExperienceStatusFilter } from '@/features/dashboard/experiences/status-filter';
import {
  MyExperiencesContext,
  newMyExperiencesContext,
} from '@/features/dashboard/experiences/context';
import { deleteAllExperiencesInCache } from '@/components/hooks/use-cached-auto-save';
import { nav, openNewTab } from '@/utils/browser';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { UserStatsKind } from '@/webapi/use-user-stats';
import { EmptyState } from '@/features/dashboard/experiences/empty-state';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { BookDemoCard } from '@/features/dashboard/experiences/book-demo-card';
import { useBookDemo } from '@/features/dashboard/experiences/use-book-demo';
import { NewExperienceTile } from './new-experience-tile';
import { useUserTrack } from '@/utils/use-user-track';
import { DemoDashboardSection } from '@/features/dashboard/demo';
import { InspirationsListingPage } from '@/features/inspirations/listing-page';
import { FeatureBit } from '@/webapi/use-auth-api';
import { breakpoints } from '@/components/responsive';

export function MyExperiences() {
  const { trackIfGoalNotReached, account, subscription, isTrialInactive } =
    useContext(AccountContext);
  const { updateUser } = useUserTrack(false);
  const ctx = newMyExperiencesContext();
  const {
    updateRow,
    setPage,
    page,
    pageSize,
    maxPages,
    total,
    unfilteredTotal,
    loading,
    experiencesList,
  } = ctx;

  const [scrollTo, setScrollTo] = useQueryParam(`scrollTo`, StringParam);
  const [expAllocationChange, setExpAllocationChange] = useState(``);
  const isInspirationEnabled = useFeatureBit(
    FeatureBit.ENABLE_INSPIRATIONS,
    false,
  );

  useAsyncEffect(async () => {
    trackIfGoalNotReached(UserStatsKind.IS_FIRST_TIME).then();
  }, []);
  useEffect(() => {
    sessionStorage.removeItem(`${account.store.alias}_aiPrompt`);
    sessionStorage.removeItem(`${account.store.alias}_aiImageUrls`);
  }, []);

  const onTileClick = async (exp: Experience) => {
    if (exp.status === ExperienceStatus.DRAFT) {
      await nav(editorRoute(exp.id));
    } else {
      await nav(detailsRoute(exp));
    }
  };

  const onNewExperience = async () => {
    await nav(editorRoute());
  };

  const onPageChanged = (page: number) => {
    setPage(page - 1);
  };

  useEffect(() => {
    deleteAllExperiencesInCache();
  }, []);

  const onDemo = () => {
    openNewTab(`https://meetings-eu1.hubspot.com/maayan-gordon`, `vsly-demo`);
  };

  useEffect(() => {
    if (!loading && experiencesList) {
      updateUser(
        {},
        {
          'Live Experiences': ctx.running,
          'Total Experiences': ctx.total,
        },
      );
    }
  }, [loading, experiencesList]);

  useEffect(() => {
    if (scrollTo === `inspirations`) {
      setTimeout(() => {
        const element = document.getElementById(`inspirations`);
        if (element) {
          element.scrollIntoView({ behavior: `smooth` });
          setTimeout(() => setScrollTo(``), 2000);
        }
      }, 1000);
    }
  }, [scrollTo]);

  const { shouldShowBookDemoBox, shouldShowBookDemoHeaderButton } = useBookDemo(
    loading,
    ctx.running,
  );

  const togglePopover = (id: string) => {
    setExpAllocationChange(id);
  };

  return (
    <MyExperiencesContext.Provider value={ctx}>
      <Wrapper>
        {shouldShowBookDemoHeaderButton && (
          <BookFreeDemoLink onClick={onDemo}>Book a demo</BookFreeDemoLink>
        )}
        <Controls>
          <ExperienceStatusFilter />
        </Controls>
        <VSpace value={3} />
        {loading && (
          <SpinnerWrapper>
            <ExperienceSpinner
              size={4}
              thickness={0.5}
              color="rgba(205,210,215)"
            />
          </SpinnerWrapper>
        )}
        {!loading && !experiencesList?.length && (
          <EmptyState hasNoExperiences={unfilteredTotal === 0} />
        )}
        {!loading && experiencesList?.length > 0 && (
          <>
            <ListWrapper>
              {experiencesList?.map((item) => (
                <ExperienceTile
                  updateRow={updateRow}
                  key={item.id}
                  onTileClick={onTileClick}
                  experience={item}
                  togglePopover={togglePopover}
                  expAllocationChange={expAllocationChange}
                />
              ))}
              <NewExperienceTile onClick={onNewExperience} />
            </ListWrapper>

            <VSpace value={4} />

            <Pagination
              value={page + 1}
              pageCount={maxPages}
              pageSize={pageSize}
              total={total}
              loading={loading}
              onPageChanged={onPageChanged}
            />
          </>
        )}
        {shouldShowBookDemoBox && (
          <>
            <VSpace value={4} />
            <BookDemoCard width="87%" />
            <VSpace value={2} />
          </>
        )}
        {isInspirationEnabled && !loading && (
          <>
            <VSpace value={4} />
            <InspirationsListingPage pageSize={6} origin="experiences" />
          </>
        )}

        <VSpace value={4} />
        {(!subscription || !isTrialInactive) && !loading && (
          <DemoDashboardSection />
        )}
      </Wrapper>
    </MyExperiencesContext.Provider>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  #live-heading {
    font-size: 2.2rem;
  }

  #inspirations {
    max-width: 87% !important;
    min-width: 87% !important;

    ${breakpoints.up(1500)} {
      max-width: 77% !important;
      min-width: 77% !important;
    }
  }

  #demo-card {
    max-width: 87% !important;
    min-width: 87% !important;

    ${breakpoints.up(1500)} {
      max-width: 77% !important;
      min-width: 77% !important;
    }
  }
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  && > div:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const SpinnerWrapper = styled.div`
  min-height: 50rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BookFreeDemoLink = styled.button`
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  appearance: none;
  outline: none;
  border: none;
  background: rgb(228, 228, 230);
  color: rgb(128, 128, 128);
  font-family: Inter, serif;
  font-weight: bold;
  letter-spacing: 0.25px;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 5rem;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;
