export enum DeviceType {
  Mobile = `mobile`,
  Desktop = `desktop`,
}

export enum WidgetType {
  CHANGELOG = 0,
  CODE_EDITOR_DEPRECATED = 1,
  APPS_CATALOG = 2,
  TARGETING = 3,
  EXPERIENCE_NAME = 4,
  CUSTOM_WIDGET = 7,
  LEGACY_VISUAL_EDITOR = 8,
  COMPOUND_EDITOR = 9,
  CHANGE_SELECTOR = 10,
  MOVE_ELEMENT = 11,
  FAKE_CLICK = 12,
  CODE_EDITOR_SELECTOR = 13,
  VISUAL_EDITOR = 14,
  POST_PURCHASE_EDITOR = 15,
  CHECKOUT_NO_MOUNT_POINTS = 16,
  CHECKOUT_APPS = 17,
  PAGE_REDIRECT = 18,
  THEME_TEST = 19,
}

export enum Role {
  UNKNON = `role_unknown`,
  SYSTEM = `system`,
  Root = `root`,
  Developer = `developer`,
  Viewer = `viewer`,
  Editor = `editor`,
  Admin = `admin`,
}
