import { useContext, useEffect, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { GradientLayoutContext } from '@/components/gradient-layout';

export const useGoals = (shouldScroll: boolean) => {
  const { experienceState } = useContext(EditorContext);
  const { currentExperience, setPrimaryGoal } = experienceState;

  const [isGoalsVisible, setIsGoalsVisible] = useState(false);
  const { scrollToEnd } = useContext(GradientLayoutContext);

  const onGoalsClick = () => {
    setIsGoalsVisible(true);
  };

  useEffect(() => {
    if (shouldScroll) {
      scrollToEnd();
    }
  }, [shouldScroll]);

  useEffect(() => {
    const removedPrimaryGoal = !currentExperience.goals?.includes(
      currentExperience?.primaryGoal,
    );
    if (removedPrimaryGoal) {
      setPrimaryGoal(currentExperience?.goals?.[0]);
    }
    if (!currentExperience.primaryGoal) {
      setPrimaryGoal(currentExperience?.goals?.[0]);
    }
  }, [currentExperience.goals]);
  return { isGoalsVisible, setIsGoalsVisible, onGoalsClick };
};
