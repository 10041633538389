import React, { useContext, useMemo, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { createGlobalStyle, css } from 'styled-components';
import { toast } from 'react-toastify';
import produce from 'immer';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
  TargetingDeviceType,
  toTargetingDeviceType,
} from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { InputModal } from '@/components/input-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { capitalize } from '@/utils/types';
import { getDeviceBasedEditorId } from '@/webapi/common';
import { fakeClickChange } from '@/features/editor/widgets/fake-click';
import { AutomationChange, WidgetChange } from '@/pkg/sdk';
import { DeviceType, Role } from '@/utils/definitions';
import { AccountContext } from '@/features/account-context';
import { upgradeWidgetChange } from '@/features/editor/widgets/shared/apps-catalog/upgrade-widget';

export function cloneChange(change: EditorDeclarativeBlock) {
  return produce(change, (draft) => {
    const id = `_loomi_addon_${new Date().getTime()}`;
    draft.editorId = id;
    draft.editorSelector = `#${id}`;
    draft.block.value[`htmlKind`] = `appendAfter`;
    draft.block.selector = `#vsly-invalid-selector`;
    if (draft.block.value?.[`env`]) {
      draft.block.value[`env`][`sectionId`] = draft.editorId;
    }
  });
}

export function ChangelogTooltip({
  change,
  isSynced,
}: {
  change: EditorDeclarativeBlock;
  isSynced: boolean;
}) {
  const {
    account: {
      store: { role },
    },
  } = useContext(AccountContext);

  const isSystemUser = useMemo(() => role === Role.SYSTEM, [role]);

  const {
    resources: { appsCatalog },
    currentExtension,
    transpiler: { globalCssId, globalJsId, asHideElementBlock },
    experienceState: {
      upsertEditorChange,
      removeEditorChange,
      currentExperience,
    },
    inspectorNav: { gotoChangeSelector },
    devicePreview: {
      editorState: { device },
      appendElement,
    },
  } = useContext(EditorContext);

  const tooltip = useRef(null);

  const {
    props: renameProps,
    show: showRenameModal,
    hide: hideRenameModal,
    fromRef: renameRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `transparent`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(23, 42),
  );

  const isCheckoutExt = useMemo(
    () => currentExtension === `shopify-checkout-extensibility`,
    [currentExtension],
  );

  const isSameDeviceAsChange = useMemo(
    () => change.targetDevice === toTargetingDeviceType(device),
    [device, change],
  );

  const oppositeDevice = useMemo<DeviceType>(() => {
    if (device === DeviceType.Desktop) return DeviceType.Mobile;
    return DeviceType.Desktop;
  }, [device]);

  const isTargetingAllDevices = useMemo<boolean>(
    () => change.targetDevice === TargetingDeviceType.ALL_DEVICES,
    [change],
  );

  const onDelete = async (ev) => {
    ev.stopPropagation();
    removeEditorChange(change);
  };

  const onUpgradeWidget = async (ev) => {
    ev.stopPropagation();
    const newChange = upgradeWidgetChange(change, appsCatalog);
    if (newChange) {
      upsertEditorChange(newChange);
    }
  };

  const onReplacePosition = async (ev) => {
    ev.stopPropagation();
    gotoChangeSelector(change);
  };

  const onCssSelectorCopy = async (ev) => {
    ev.stopPropagation();
    await navigator.clipboard.writeText(change.block.selector);
    toast(`Selector Copied Successfully`, {
      theme: `colored`,
      type: `success`,
    });
  };

  const onCopyWidgetId = async (ev) => {
    ev.stopPropagation();
    await navigator.clipboard.writeText(
      (change.block.value as WidgetChange).env[`sectionId`],
    );
    toast(`Selector Copied Successfully`, {
      theme: `colored`,
      type: `success`,
    });
  };

  const onDuplicate = async (ev) => {
    ev.stopPropagation();
    const clone = cloneChange(change);
    gotoChangeSelector(clone, true);
  };

  const onShowRename = (ev: any) => {
    ev.stopPropagation();
    hideTooltip();
    showRenameModal();
  };

  const onToggleTargetAllDevices = (ev: any) => {
    ev.stopPropagation();
    hideTooltip();
    const newChange = produce(change, (draft) => {
      if (draft.targetDevice === TargetingDeviceType.DESKTOP) {
        normalizeDesktopValues(draft);
      }
      draft.targetDevice = isTargetingAllDevices
        ? toTargetingDeviceType(device)
        : TargetingDeviceType.ALL_DEVICES;
    });
    upsertEditorChange(newChange);
  };

  const onCopyToDevice = (ev: any) => {
    ev.stopPropagation();
    hideTooltip();
    const isSameDevice = change.targetDevice === toTargetingDeviceType(device);
    const toDevice = isSameDevice ? oppositeDevice : device;
    if (change.editorKind === EditorChangeKind.GLOBAL_CSS) {
      const newChange = produce(change, (draft) => {
        draft.editorId = globalCssId(currentExperience.id, toDevice);
        draft.targetDevice = toTargetingDeviceType(toDevice);
      });
      upsertEditorChange(newChange);
    } else if (change.editorKind === EditorChangeKind.GLOBAL_JS) {
      const newChange = produce(change, (draft) => {
        draft.editorId = globalJsId(currentExperience.id, toDevice);
        draft.targetDevice = toTargetingDeviceType(toDevice);
      });
      upsertEditorChange(newChange);
    } else if (change.editorKind === EditorChangeKind.NEW_COMPONENT) {
      const clone = produce(change, (draft) => {
        if (isSameDevice && toDevice === DeviceType.Mobile) {
          normalizeDesktopValues(draft);
        }
        const id = `_loomi_addon_${new Date().getTime()}`;
        draft.editorId = id;
        draft.editorSelector = `#${id}`;
        if (draft.block.value?.[`env`]) {
          draft.block.value[`env`][`sectionId`] = draft.editorId;
        }
        draft.targetDevice = toTargetingDeviceType(toDevice);
      });
      upsertEditorChange(clone, false, true);
    } else if (change.editorKind === EditorChangeKind.FAKE_CLICK) {
      const clone = produce(change, (draft) => {
        const id = getDeviceBasedEditorId(
          change.block.selector,
          toDevice,
          currentExperience.experienceCreationVersion,
        );

        const newChange = fakeClickChange(
          id,
          (change.block.value as AutomationChange).steps,
        );
        draft.editorId = newChange.editorId;
        draft.editorSelector = newChange.editorSelector;
        draft.targetDevice = toTargetingDeviceType(toDevice);
      });
      upsertEditorChange(clone);
    } else if (change.editorKind === EditorChangeKind.HIDE_COMPONENT) {
      const clone = produce(change, (draft) => {
        const newChange = asHideElementBlock(change.editorSelector, toDevice);
        draft.editorId = newChange.editorId;
        draft.editorSelector = newChange.editorSelector;
        draft.targetDevice = toTargetingDeviceType(toDevice);
      });
      upsertEditorChange(clone);
    } else {
      toast(`Oops, can't copy this change`, {
        theme: `colored`,
        type: `error`,
        className: css({
          fontFamily: `JetBrains Mono, Serif`,
          fontWight: `700`,
        }),
      });
    }
  };

  const hideTooltip = () => {
    setTimeout(() => {
      tooltip.current.tooltipRef.style.display = `none`;
    }, 100);
    setTimeout(() => {
      tooltip.current.tooltipRef.style.display = `block`;
    }, 500);
  };

  const onRename = (name: string) => {
    const updated = produce(change, (draft) => {
      draft.hint = name;
    });
    upsertEditorChange(updated);
    hideRenameModal();
  };

  const onNewContent = (ev, kind) => {
    ev.stopPropagation();
    if (change.editorKind === EditorChangeKind.NEW_COMPONENT) {
      appendElement(change.editorSelector, kind);
    }
  };

  return (
    <>
      <SharedElementOverlay {...renameProps}>
        <InputModal
          title="Rename Change?"
          description="Give a meaningful name for this change"
          yesCaption="Rename"
          noCaption="Discard"
          placeholder=""
          initialValue={change.hint}
          onConfirm={onRename}
          onDiscard={hideRenameModal}
        />
      </SharedElementOverlay>
      <StyledTooltip />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ReactTooltip
        ref={tooltip}
        id={change.editorId}
        place="bottom"
        effect="solid"
        clickable
        backgroundColor="#ffffff"
        textColor="#111111"
        arrowColor="#ffffff"
        className="exp-options-tooltip-cls"
        scrollHide
        delayHide={200}
        event="click"
        eventOff="mouseout"
      >
        <Wrapper>
          {isSynced && (
            <Row onClick={(e) => onNewContent(e, `appendBefore`)}>
              Add Before
            </Row>
          )}
          {isSynced && (
            <Row onClick={(e) => onNewContent(e, `appendAfter`)}>Add After</Row>
          )}
          {isSynced &&
            !isCheckoutExt &&
            change.editorKind === EditorChangeKind.NEW_COMPONENT && (
              <Row onClick={onDuplicate}>Duplicate</Row>
            )}
          {isSynced &&
            !isCheckoutExt &&
            change.editorKind === EditorChangeKind.NEW_COMPONENT && (
              <Row onClick={onReplacePosition}>Replace Position</Row>
            )}
          {[
            EditorChangeKind.NEW_COMPONENT,
            EditorChangeKind.EDIT_COMPOUND,
            EditorChangeKind.HIDE_COMPONENT,
          ].includes(change.editorKind) &&
            !isCheckoutExt && (
              <>
                <Row onClick={onCssSelectorCopy}>Copy CSS Selector</Row>
                {!!change?.widgetProps?.appId && (
                  <Row onClick={onCopyWidgetId}>Copy Widget Id</Row>
                )}
                <Row ref={renameRef} onClick={onShowRename}>
                  Rename
                </Row>
              </>
            )}
          {!isTargetingAllDevices &&
            !isCheckoutExt &&
            [
              EditorChangeKind.GLOBAL_CSS,
              EditorChangeKind.GLOBAL_JS,
              EditorChangeKind.NEW_COMPONENT,
              EditorChangeKind.HIDE_COMPONENT,
              EditorChangeKind.FAKE_CLICK,
            ].includes(change.editorKind) && (
              <Row onClick={onCopyToDevice}>
                Copy to{` `}
                {capitalize(isSameDeviceAsChange ? oppositeDevice : device)}
              </Row>
            )}
          {[
            EditorChangeKind.GLOBAL_CSS,
            EditorChangeKind.GLOBAL_JS,
            EditorChangeKind.NEW_COMPONENT,
            EditorChangeKind.HIDE_COMPONENT,
            EditorChangeKind.FAKE_CLICK,
          ].includes(change.editorKind) && (
            <Row onClick={onToggleTargetAllDevices}>
              Target{` `}
              {isTargetingAllDevices ? capitalize(device) : `All Devices`}
            </Row>
          )}
          <Row onClick={onDelete}>Delete</Row>
          {isSystemUser && change?.block?.kind === `widget` && (
            <Row onClick={onUpgradeWidget}>Upgrade to latest</Row>
          )}
        </Wrapper>
      </ReactTooltip>
    </>
  );
}

const Wrapper = styled.div`
  && > div:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  border-top: 1px solid transparent;
  padding: 1.5rem 2rem;
  align-items: center;

  img {
    object-fit: contain !important;
  }

  transition: background-color 0.3s linear;

  :hover {
    background: #fafafa;
  }
`;

const StyledTooltip = createGlobalStyle`
  .exp-options-tooltip-cls {
    border-radius: 1rem!important;
    box-shadow: 0 12px 18px 2px rgba(0,0,0,0.14), 0 5px 23px 4px rgba(0,0,0,0.12), 0 6px 8px -4px rgba(0,0,0,0.2)!important;
    padding: 0!important;
    width: 18rem;
    opacity: 1!important;

    font-family: Inter, serif;
  }
`;

function normalizeDesktopValues(obj) {
  try {
    if (typeof obj !== `object` || obj === null) return obj;
    Object.entries(obj).forEach(([key, value]) => {
      if (key === `desktop` && typeof value === `object`) {
        Object.assign(obj, value);
        // Optional behavior
        // delete obj.desktop;
      } else if (typeof value === `object` && value !== null) {
        obj[key] = normalizeDesktopValues(value);
      }
    });
    return obj;
  } catch {
    return obj;
  }
}
