import React, { useContext, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import { TargetingControlsGrid } from '@/features/editor/widgets/shared/targeting-controls/targeting-controls-grid';
import { ChangesCard } from '@/features/editor/widgets/targeting/changes-card';
import { CircularCheckmark } from '@/components/circular-checkmark';
import { AllocationCard } from '@/features/editor/widgets/targeting/allocation-card';
import { DeviceType } from '@/utils/definitions';
import { EditorContext } from '@/features/editor/context/editor-context';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { ExperienceSchedule } from '@/features/editor';

export interface InspectorTargetingViewProps {
  hideGoogleAnalytics?: boolean;
  hideABGoals?: boolean;
  disableAudiencePicker?: boolean;
  disablePlacementPicker?: boolean;
  disableDevicesPicker?: boolean;
  disableTriggerPicker?: boolean;
  disableThemePickerPicker?: boolean;

  onChangesClicked?: () => void;
}

export function InspectorTargetingView({
  hideGoogleAnalytics,
  hideABGoals,
  disableDevicesPicker,
  disableAudiencePicker,
  disablePlacementPicker,
  disableTriggerPicker,
  disableThemePickerPicker,
  onChangesClicked,
}: InspectorTargetingViewProps) {
  const { experienceState, devicePreview } = useContext(EditorContext);

  const {
    editorState: { device },
  } = devicePreview;
  const { currentExperience, changeAllocation } = experienceState;

  const isMultiVariant = useMemo(
    () => currentExperience.variants.length > 1,
    [currentExperience],
  );

  const [isABTest, setIsABTest] = useState(
    currentExperience.variants[0].chance < 100 ||
      currentExperience.variants.length > 1,
  );

  const [shouldScroll, setShouldScroll] = useState(false);

  const onABTest = (isSelected: boolean) => {
    if (!isSelected && isMultiVariant) {
      return;
    }

    const updated = produce(currentExperience.variants[0], (draft) => {
      draft.chance = 50;
    });
    changeAllocation(updated);
    setIsABTest(isSelected);
    setShouldScroll(isSelected);
  };

  useEffect(() => {
    if (!isABTest) {
      const updated = produce(currentExperience.variants[0], (draft) => {
        draft.chance = 100;
      });
      changeAllocation(updated);
    }
  }, [isABTest]);

  return (
    <Wrapper device={device}>
      <GeneralText>
        1. What audience and where will the experience run?
      </GeneralText>
      <VSpace value={2} />

      <TargetingControlsGrid
        disableDevicesPicker={disableDevicesPicker}
        disableAudiencePicker={disableAudiencePicker}
        disableTriggerPicker={disableTriggerPicker}
        disablePlacementPicker={disablePlacementPicker}
        disableThemePickerPicker={disableThemePickerPicker}
      />
      <VSpace value={4} />

      <GeneralText>2. The changes</GeneralText>
      <VSpace value={2} />
      <ChangesCard onChangesClicked={onChangesClicked} />
      <VSpace value={4} />

      <GeneralText>3. Would you like to run an A/B validation?</GeneralText>
      <VSpace value={2} />
      <CircularCheckmark
        onChange={(sel) => onABTest(!sel)}
        selected={!isABTest}
        disabled={isMultiVariant}
        caption="No thanks"
        disabledHint="Set the required variant to 100%, or remove the excess variants"
      />
      <VSpace value={2} />
      <CircularCheckmark
        onChange={(sel) => onABTest(sel)}
        selected={isABTest}
        caption="Yes sure"
      />

      {isABTest ? (
        <>
          <VSpace value={2} />
          <AllocationCard
            shouldScroll={shouldScroll}
            hideGa={hideGoogleAnalytics}
            hideGoals={hideABGoals}
          />
        </>
      ) : null}

      <VSpace value={4} />

      <ExperienceSchedule />

      <VSpace value={3} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-right: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `0` : `20rem`};

  .checkbox_wrapper {
    height: auto;
  }
`;
