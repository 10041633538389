import React, { MutableRefObject, useContext } from 'react';
import styled from 'styled-components';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { GradientModal } from '@/components/gradient-modal';
import {
  CustomQueryHeaderWrapper,
  Footer,
} from '@/features/editor/widgets/shared/modals/commons';
import { centered } from '@/components/use-shared-element';
import { QueryBuilder } from '@/components/query-builder';
import { VSpace } from '@/components/spacing';
import { NameForm } from '@/features/dashboard/audiences/name-form';
import { AudiencesContext } from '@/features/dashboard/audiences/context';

interface Props {
  fromRef: MutableRefObject<any>;
}

export function PrimaryAudienceModal({ fromRef }: Props) {
  const {
    audience,
    audienceProps,
    nameStep,
    name,
    onQueryBuilderChange,
    onSave,
    loading,
    isModalVisible: isVisible,
    setIsModalVisible: setIsVisible,
  } = useContext(AudiencesContext);

  if (loading) {
    return null;
  }
  const modalCfg = getModalConfig(fromRef);
  return (
    <GradientModal
      {...modalCfg}
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      header={<Header />}
      footer={
        <Footer
          onClick={onSave}
          text={nameStep ? `Save` : `Next`}
          isDisabled={loading || (nameStep && !name)}
        />
      }
    >
      <Container>
        {nameStep ? (
          <NameForm />
        ) : (
          <>
            <span>
              Use our query builder to tailor the target audience to the
              experience
            </span>
            <QueryBuilder
              noOnboarding
              onChange={onQueryBuilderChange}
              initialProps={audienceProps}
              initialState={audience}
            />
          </>
        )}
      </Container>
    </GradientModal>
  );
}

const BackBtn = styled.button`
  position: relative;
  left: -2rem !important;
  top: -1px !important;
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  &:active {
    cursor: default;
    opacity: 1;
  }
`;

const Col = styled.div`
  position: relative;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

function Header() {
  const { nameStep, setNameStep } = useContext(AudiencesContext);

  return (
    <Col>
      <CustomQueryHeaderWrapper>
        {nameStep
          ? `Set a name for the audience`
          : `Create a new primary audience`}
        {nameStep && (
          <BackBtn type="button" onClick={() => setNameStep(false)}>
            <MdOutlineArrowBackIosNew color="#c5c8cc" size={25} />
          </BackBtn>
        )}
      </CustomQueryHeaderWrapper>

      <VSpace />
      <VerticalSep />
    </Col>
  );
}

const VerticalSep = styled.div`
  margin: 1rem 0 2rem 0;
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
`;

function getModalConfig(fromRef: React.MutableRefObject<any>) {
  const targetPosAndSize = centered(60, 90);

  return {
    targetPosAndSize,
    closeTop: `0.5rem`,
    closeRight: `2.5rem`,
    fromStyle: {
      borderRadius: `5rem`,
      backgroundColor: `#DEDEDE`,
      padding: `2rem 0 0 0`,
    },
    toStyle: {
      borderRadius: `1rem`,
      backgroundColor: `#FFFFFF`,
      padding: `2rem 0 0 0`,
    },
    showBackdrop: true,
    fromRef,
    overflowTop: { heightInRem: 8 },
    overflowBottom: { heightInRem: 8, turnPointInPercent: 30 },
  };
}
