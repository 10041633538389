import styled from 'styled-components';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import produce from 'immer';
import { Flexbox } from '@/components/flex';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import {
  EditorDeclarativeBlock,
  Experience,
} from '@/webapi/use-experience-api';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';
import { TitleWrapper } from '@/features/editor/widgets/changelog';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { VSpace } from '@/components/spacing';
import { TargetingControlsList } from '@/features/editor/widgets/shared/targeting-controls/targeting-controls-list';

import { ThemesAllocation } from '@/features/editor/widgets/theme-test/themes-allocation';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  useWidgetControls,
  WidgetControlsProps,
} from '@/features/editor/widgets/shared/use-widget-controls';
import {
  ExperienceSchedule,
  isThemeTestPublishNotAllowed,
  showThemeTestWarningToast,
} from '@/features/editor';
import { handleInvalidAlloc } from '@/features/editor/widgets/targeting/shared';
import { AccountContext } from '@/features/account-context';
import {
  PublishModal,
  usePublishModal,
} from '@/features/editor/use-publish-modal';
import {
  ScheduleModal,
  useScheduleModal,
} from '@/features/editor/use-schedule-modal';
import { ConfirmModal } from '@/components/confirm-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { DevicePreviewHook } from '@/features/editor/context/use-device-preview';
import { ThemeChange } from '@/pkg/sdk';
import { maybe } from '@/features/details/utils';
import { Role } from '@/utils/definitions';

export interface ThemeTestWidgetProps {
  originalChange: EditorDeclarativeBlock;
}

export function ThemeTestWidget({ originalChange }: ThemeTestWidgetProps) {
  const {
    experienceControls,
    devicePreview,
    experienceState: { currentExperience, changeAllocation },
  } = useContext(EditorContext);
  looseFocus(devicePreview);

  const isDesk = useIsPreviewingDesktop();
  const { WidgetControls, controlsProps } = useWidgetControls(
    () => 1,
    `Publish`,
  );
  const {
    account: {
      store: { isTestStore, role },
    },
  } = useContext(AccountContext);
  const { scheduleModalProps, publishModalProps, onNextStep, isScheduled } =
    getNextStep(currentExperience, controlsProps, isTestStore, role);
  controlsProps.onNextStep = onNextStep;
  const { sharedElement, closeAndNavigateToDashboard, isNewAndUnsaved } =
    experienceControls;
  if (isNewAndUnsaved()) {
    controlsProps.nextTitle = `Next`;
    controlsProps.onNextStep = controlsProps.onSave;
  } else {
    controlsProps.nextTitle = isScheduled ? `Schedule` : `Publish`;
  }
  const sourceUrlRef = useRef(null);
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (isInitialCreation(currentExperience)) {
      changeAllocation(
        produce(currentExperience.variants[0], (draft) => {
          draft.chance = 50;
        }),
        true,
      );
    }
    setInit(true);
  }, []);
  if (!init) {
    return null;
  }
  return (
    <Wrapper isDesk={isDesk} ref={sourceUrlRef}>
      <PublishModal {...publishModalProps} />
      <ScheduleModal {...scheduleModalProps} />
      <SharedElementOverlay {...sharedElement.props} fromElement={sourceUrlRef}>
        <ConfirmModal
          neutral
          onDiscard={sharedElement.hide}
          onConfirm={closeAndNavigateToDashboard}
          title="You have unsaved changes"
          description="Are you sure you want to discard the changes?"
          yesCaption="Discard Changes"
          noCaption="Continue Editing"
        />
      </SharedElementOverlay>
      <InspectorWidgetLayout
        progress={75}
        title={
          <TitleWrapper>
            <EditorRenameButton />
            <DescriptionEditButton />
          </TitleWrapper>
        }
        backCaption="< back to dashboard"
        onBackClicked={() => experienceControls.onCloseEditorPageClicked()}
        footer={<WidgetControls {...controlsProps} showHistory={false} />}
      >
        <GeneralText>
          1. What audience and where will the experience run?
        </GeneralText>
        <VSpace value={2} />
        <TargetingControlsList
          disablePlacementPicker
          hideTriggerPicker
          disableAudiencePicker
          hideVariationPicker
        />
        <VSpace value={3} />
        <GeneralText>
          2. Select the theme you want to choose with Control
        </GeneralText>
        <VSpace value={2} />
        <ThemesAllocation originalChange={originalChange} />
        <VSpace value={2} />
        <ExperienceSchedule onlySpecificDates />
        <VSpace value={2} />
      </InspectorWidgetLayout>
    </Wrapper>
  );
}
const Wrapper = styled(Flexbox)`
  && {
    height: 100%;
    width: ${(p: any) => (p.isDesk ? `100%` : `65%`)};

    #${INSPECTOR_BACK_ID} {
      z-index: 31;
    }
  }
`;

const getNextStep = (
  currentExperience: Experience,
  controlsProps: WidgetControlsProps,
  isTestStore: boolean,
  role: Role,
) => {
  const isScheduled = useMemo(
    () => currentExperience?.schedule?.type !== undefined,
    [currentExperience?.schedule?.type],
  );
  const publishModalProps = usePublishModal(controlsProps.nextRef);
  const scheduleModalProps = useScheduleModal(
    controlsProps.nextRef,
    currentExperience,
  );

  const onNextStep = async () => {
    if (handleInvalidAlloc(currentExperience)) {
      return Promise.resolve();
    }
    if (isTestStore && role !== Role.SYSTEM) {
      toast(`Publishing experiences is not available for test stores.`, {
        theme: `colored`,
        type: `info`,
      });
      return Promise.resolve();
    }
    const allVariantsHaveSelectedATheme = currentExperience.variants.reduce(
      (p, c) =>
        p &&
        typeof maybe(
          () => (c.changes[0].block.value as ThemeChange).targetThemeId,
        ) === `number`,
      true,
    );
    if (!allVariantsHaveSelectedATheme) {
      toast(`Please select a theme for all experience variants.`, {
        theme: `colored`,
        autoClose: 10000,
        type: `info`,
      });
      return Promise.resolve();
    }
    const response = await isThemeTestPublishNotAllowed(currentExperience);
    if (response?.value) {
      showThemeTestWarningToast(response);
      return Promise.resolve();
    }

    if (isScheduled) {
      scheduleModalProps.show();
    } else {
      publishModalProps.show();
    }
    return Promise.resolve();
  };
  return {
    onNextStep,
    publishModalProps,
    scheduleModalProps,
    isScheduled,
  };
};

const looseFocus = (devicePreview: DevicePreviewHook) => {
  useEffect(() => devicePreview.disableNavigator(), []);
};

const isInitialCreation = (currentExperience: Experience) =>
  !!maybe(() => {
    const hasOneVariant = currentExperience.variants.length === 1;
    const variantIs100Percent = currentExperience.variants[0].chance === 100;
    const noChanges =
      typeof currentExperience.variants[0].changes === `undefined`;
    return hasOneVariant && variantIs100Percent && noChanges;
  });
