import { InputType } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { Experience } from '@/webapi/use-experience-api';

export function rtlSettings(
  appsCatalog: any[],
  experience: Experience,
  isShopRtl: boolean,
  setIsShopRTL: (isRtlValue: boolean) => Promise<void>,
) {
  const isRtlExperience = hasExperienceDirection(`rtl`, experience.variants);
  const isLtrExperience = hasExperienceDirection(`ltr`, experience.variants);

  if (isShopRtl) {
    if (!isLtrExperience) {
      updateDefaultDirections(`rtl`, appsCatalog);
    } else {
      setIsShopRTL(false);
    }
  } else if (isRtlExperience && !isLtrExperience) {
    setIsShopRTL(true).then(() => updateDefaultDirections(`rtl`, appsCatalog));
  }
}

function hasExperienceDirection(direction: string, obj: any) {
  if (obj && typeof obj === `object`) {
    if (Array.isArray(obj)) {
      return obj.some((item) => hasExperienceDirection(direction, item));
    }

    if (
      obj.key === `direction` &&
      obj.type === `SWITCH` &&
      obj.values?.value === direction
    ) {
      return true;
    }

    return Object.values(obj).some((value) =>
      hasExperienceDirection(direction, value),
    );
  }

  return false;
}

function updateDefaultDirections(direction: string, appsCatalog: any[]) {
  appsCatalog?.forEach((_, appIdx) =>
    appsCatalog[appIdx].widgets.forEach((_, widgetIdx) => {
      appsCatalog[appIdx].widgets[
        widgetIdx
      ].blueprint.schema.customizations.forEach((_, custIdx) => {
        appsCatalog[appIdx].widgets[widgetIdx].blueprint.schema.customizations[
          custIdx
        ].components.forEach((_, compIdx) => {
          appsCatalog[appIdx].widgets[
            widgetIdx
          ].blueprint.schema.customizations[custIdx].components[
            compIdx
          ].specs.forEach((spec, specIdx) => {
            if (spec.type === InputType.SWITCH && spec.key === `direction`) {
              appsCatalog[appIdx].widgets[
                widgetIdx
              ].blueprint.schema.customizations[custIdx].components[
                compIdx
              ].specs[specIdx].values[`value`] = direction;
            }
          });
        });
      });
    }),
  );
}
