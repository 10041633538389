import React, { useContext, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { IoEyeOutline } from 'react-icons/io5';
import { TextInput } from '@/components/text-input';
import { HSpace, VSpace } from '@/components/spacing';
import { EditorContext } from '@/features/editor/context/editor-context';
import { ConfirmModal } from '@/components/confirm-modal';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { BigButton } from '@/components/big-button';
import { CloseButton } from '@/components/close-button';
import { ExperienceVariant } from '@/webapi/use-experience-api';
import { ThemeChange } from '@/pkg/sdk';
import { Anchor } from '@/features/editor/widgets/theme-test/shared';

export function ThemeVariantName({
  disableNameEdit,
  bg,
  disableRemove,
  onShowThemesModal,
  variant,
}: {
  bg?: string;
  disableNameEdit?: boolean;
  disableRemove?: boolean;
  onShowThemesModal?: () => void;
  variant?: ExperienceVariant;
}) {
  const edtCtx = useContext(EditorContext);
  const defaultValue = variant.name;
  const variantId = variant.id;
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [name, setName] = useState(defaultValue);
  const initialName = useRef<string>(defaultValue);
  const handleEdit = () => {
    if (isEditing) {
      edtCtx.experienceState.renameVariant(variantId, name);
    }
    setIsEditing(!isEditing);
  };
  const {
    resources: { themes },
  } = useContext(EditorContext);

  const targetThemeId = (variant?.changes?.[0]?.block?.value as ThemeChange)
    ?.targetThemeId;
  const themeName = themes?.find((theme) => theme.id === targetThemeId)?.name;
  return (
    <Wrapper>
      {isEditing ? (
        <FlexRow>
          <TextInput
            style={{ width: `145px` }}
            defaultValue={defaultValue}
            value={name}
            onChange={(x) => {
              const newName = x.target.value;
              setName(newName);
              setHasChanged(newName !== initialName.current);
            }}
            maxLength="12"
          />
          <HSpace value={1} />
          <FlexCenter>
            <CloseWrap onClick={() => setIsEditing(false)} color="#b0b9c0" />
          </FlexCenter>
          <HSpace />
          <FlexCenter style={{ visibility: hasChanged ? `visible` : `hidden` }}>
            <BigButton
              background="blue"
              border="blue"
              size="small"
              fillWidth={false}
              onClick={handleEdit}
            >
              <strong>Save</strong>
            </BigButton>
          </FlexCenter>
        </FlexRow>
      ) : (
        <>
          <FlexCol>
            <NameContainer>
              <TextSpan>{defaultValue}</TextSpan>
              <PencilIcon
                disableNameEdit={disableNameEdit}
                handleEdit={handleEdit}
              />
            </NameContainer>
            <VSpace />
            <SelectThemeText onClick={onShowThemesModal} isBlue={!themeName}>
              {themeName ? (
                <FlexRow>
                  <GreyDot />
                  <ThemeName>{themeName}</ThemeName>
                </FlexRow>
              ) : (
                `Select Theme >`
              )}
            </SelectThemeText>
            <VSpace />
          </FlexCol>

          {!disableRemove && (
            <Actions
              targetThemeId={targetThemeId}
              bg={bg}
              handleDelete={() =>
                edtCtx.experienceState.deleteVariant(variantId)
              }
              editNow={() =>
                edtCtx.experienceState.selectActiveVariant(variantId)
              }
            />
          )}
        </>
      )}
    </Wrapper>
  );
}
const GreyDot = styled.span`
  display: block;
  background: #9ea8b1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
`;
const SelectThemeText = styled.p`
  font-family: Inter, serif;
  margin: 0;

  color: ${(p) => (p.isBlue ? `blue` : `black`)};
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: 700;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const NameContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;

  span {
    margin-right: 1rem;
  }
`;
const CloseWrap = styled(CloseButton)`
  && {
    opacity: 0.8;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e3e9ee;
    padding: 5px;

    :hover {
      opacity: 0.5;
      cursor: pointer;
    }

    :active {
      opacity: 1;
      cursor: default;
    }
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  color: #5b656e;
  width: 100%;
`;

const TextSpan = styled.span`
  font-family: 'Inter', serif;
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;
const ThemeName = styled.span`
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  padding: 0.5rem 0 0.5rem 2rem;
  display: flex;
  position: relative;
  width: 85%;
`;

function Actions({
  handleDelete,
  bg,
  targetThemeId,
}: {
  handleDelete: () => void;
  editNow: (id: string) => void;
  bg: string;
  targetThemeId?: number;
}) {
  const edtCtx = useContext(EditorContext);
  const isMultiVariant =
    edtCtx?.experienceState?.currentExperience?.variants?.length > 1;
  const ref = useRef();
  const { props, show, hide } = sharedOverlayProps(ref);
  const isEnabled =
    edtCtx?.experienceState?.currentExperience?.themeId === targetThemeId;
  return (
    <EditContainer>
      <ActionsContainer>
        <HSpace value={1} />
        <SharedElementOverlay {...props}>
          <ConfirmModal
            onDiscard={hide}
            onConfirm={handleDelete}
            title="Are you sure?"
            description="Are you sure you want to delete this variation?"
            yesCaption="Yes"
            noCaption="No"
          />
        </SharedElementOverlay>
        {isMultiVariant && (
          <BtnWrapper bg={bg} onClick={show}>
            <span ref={ref}>Remove</span>
          </BtnWrapper>
        )}
        <HSpace value={2} />
        <BtnWrapper bg={bg}>
          {targetThemeId && (
            <Anchor
              onClick={() => {
                edtCtx.experienceState.selectTheme(targetThemeId);
              }}
            >
              <IoEyeOutline size={24} color={isEnabled ? `black` : `#aeafb1`} />
            </Anchor>
          )}
        </BtnWrapper>
        <HSpace />
      </ActionsContainer>
      <HSpace />
    </EditContainer>
  );
}

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const EditContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(p: { isEditing: boolean }) =>
    p.isEditing ? `start` : `space-between`};
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: ${(p: { isActive: boolean }) => (p.isActive ? `0.5` : `1`)};
  color: #9199a6;

  span {
    text-decoration: underline;
  }

  font-family: Inter, serif;
  font-size: ${(p) => (p.bg ? `1.25` : `1`)}rem;

  :hover {
    opacity: ${(p: { isActive: boolean }) => (p.isActive ? `0.5` : `0.6`)};
    cursor: ${(p: { isActive: boolean }) =>
      p.isActive ? `default` : `pointer`};
  }

  :active {
    opacity: ${(p: { isActive: boolean }) => (p.isActive ? `0.5` : `1`)};
    cursor: default;
  }
`;

const EditIconWrapper = styled.div`
  color: #9199a6;
  font-family: Inter, serif;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }

  :active {
    opacity: 1;
    cursor: default;
  }
`;

function sharedOverlayProps(ref: React.MutableRefObject<undefined>) {
  const { props, show, hide } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    ref,
    () => centered(18, 42),
  );
  return { props, show, hide };
}

function PencilIcon({
  disableNameEdit,
  handleEdit,
}: {
  disableNameEdit: boolean;
  handleEdit: () => void;
}) {
  return !disableNameEdit ? (
    <EditIconWrapper onClick={handleEdit}>
      <div style={{ width: `10px`, marginTop: `4px` }}>
        <StaticImage
          src="../../../../assets/pencil.svg"
          alt="edit"
          placeholder="none"
          loading="eager"
          height={12}
        />
      </div>
    </EditIconWrapper>
  ) : null;
}
