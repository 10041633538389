import React, { useContext } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { VSpace } from '@/components/spacing';
import { CircularCheckmark } from '@/components/circular-checkmark';
import { YesNo } from '@/webapi/use-experience-api';

export function GaEvents({ hideGa }: { hideGa?: boolean }) {
  const {
    experienceState: { currentExperience, toggleGaEvent },
    resources: { gaIntegrationEnabled },
  } = useContext(EditorContext);
  return (
    gaIntegrationEnabled &&
    !hideGa && (
      <>
        <VSpace value={3} />
        <CircularCheckmark
          selected={currentExperience.targeting.sendGAEvents === YesNo.YES}
          caption="Send events to google analytics"
          onChange={toggleGaEvent}
        />
      </>
    )
  );
}
