import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useIntersection } from 'react-use';
import { DefaultTypography } from '@/components/typography';
import { ComponentGroup } from '@/features/editor/widgets/custom-widget/inputs/shared/components-group';
import { GroupDisableButton } from '@/features/editor/widgets/custom-widget/inputs/shared/group-disable-button';
import { Flexbox } from '@/components/flex';
import { Customization } from '@/webapi/use-widget-catalog-api';
import { DeviceType } from '@/utils/definitions';
import { EditorContext } from '@/features/editor/context/editor-context';
import { getSpecHiddenStyles } from '@/features/editor/widgets/custom-widget/shared/utils';
import { MaybeFiltered } from '@/features/editor/widgets/custom-widget/shared/shared-styles';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { StateToggleButton } from '@/features/editor/widgets/custom-widget/inputs/shared/state-toggle-button';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';

export interface CustomizationGroupProps {
  custIdx: number;
  customization: Customization;
  onDisabledStatusChanged: (custIdx: number, isDisabled: boolean) => void;
  isOpen?: boolean;
  getResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    device: DeviceType,
  ) => any;
  setResponsiveValue: (
    custId: number,
    compId: number,
    specId: number,
    key: string,
    value: any,
    device: DeviceType,
  ) => void;
  stickyHeader?: string;
  stickyHeaderEnabled?: boolean;
  setStickyHeader?: (st: string) => void;
  setStickyEnabled?: (v: boolean) => void;
}
export function CustomizationGroup({
  custIdx,
  customization,
  onDisabledStatusChanged,
  getResponsiveValue,
  setResponsiveValue,
  stickyHeader,
  stickyHeaderEnabled,
  setStickyHeader,
  setStickyEnabled,
}: CustomizationGroupProps) {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  const { evaluateRule, currentSchema } = useContext(CustomWidgetContext);

  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const [isDisabled, setIsDisabled] = useState(
    customization.isDisabled || false,
  );

  const [inHoverMode, setInHoverMode] = useState(false);

  const [isOpen, setIsOpen] = useState(!isDisabled);

  useEffect(() => {
    if (currentSchema?.customizations?.[custIdx]?.isDisabled !== isDisabled) {
      setIsDisabled(currentSchema?.customizations?.[custIdx]?.isDisabled);
      setIsOpen(!currentSchema?.customizations?.[custIdx]?.isDisabled);
    }
  }, [currentSchema?.customizations?.[custIdx]?.isDisabled, undoRedoCount]);
  const hiddenComponents = useMemo(() => {
    if (!evaluateRule) return [];
    return customization?.components
      ?.filter((comp) => evaluateRule(comp?.hideWhen))
      ?.map((comp) => comp.key);
  }, [customization, device]);

  const onToggle = () => {
    if (isDisabled) return;
    setIsOpen(!isOpen);
  };

  const onStateSelect = (e) => {
    e.stopPropagation();
    setInHoverMode(!inHoverMode);
  };

  const onDisableSection = (ev) => {
    ev.stopPropagation();
    setIsDisabled(!isDisabled);
    setIsOpen(isDisabled);
    onDisabledStatusChanged(custIdx, !isDisabled);
  };
  const wrapperRef = React.useRef(null);
  const intersection = useIntersection(wrapperRef, {
    root: document.getElementById(`vslyGradientScroller`),
    rootMargin: `-90px 0px 0px 0px`,
    threshold: 0.01,
  });

  useEffect(() => {
    const elem = document.getElementById(`vslyGradientScroller`);
    const handler = () => {
      if (
        elem?.scrollTop > 0 &&
        wrapperRef?.current?.getBoundingClientRect().top < 75 &&
        intersection?.isIntersecting
      ) {
        setStickyHeader(customization?.name);
        setStickyEnabled(true);
      } else if (elem?.scrollTop <= 0) {
        setStickyEnabled(false);
      }
    };

    elem?.addEventListener(`scroll`, handler);

    return () => elem?.removeEventListener(`scroll`, handler);
  }, [intersection]);

  return (
    <Wrapper
      ref={wrapperRef}
      style={{ ...getSpecHiddenStyles(customization, device) }}
      key={customization.key}
    >
      <HeaderSection onClick={onToggle}>
        <Text
          isHidden={stickyHeaderEnabled && stickyHeader === customization.name}
        >
          {customization.name}
        </Text>
        <Flexbox direction="row" gap="1rem">
          {useIsPreviewingDesktop() && customization?.hasHoverState && (
            <StateToggleButton isHover={inHoverMode} onClick={onStateSelect} />
          )}
          {customization.canBeDisabled && (
            <GroupDisableButton
              isDisabled={isDisabled}
              onClick={onDisableSection}
            />
          )}
          <Text
            style={{
              opacity: isDisabled ? `0` : `1`,
              pointerEvents: isDisabled ? `none` : `auto`,
            }}
          >
            {isOpen ? `-` : `+`}
          </Text>
        </Flexbox>
      </HeaderSection>
      <ContentSection isOpen={isOpen}>
        {customization.components.map((comp, idx) => (
          <MaybeFiltered
            key={`${comp?.key}-${comp?.name}`}
            isVisible={!hiddenComponents?.includes(comp.key) && !isDisabled}
          >
            <ComponentGroup
              custIdx={custIdx}
              customization={customization}
              compIdx={idx}
              component={comp}
              getResponsiveValue={getResponsiveValue}
              setResponsiveValue={setResponsiveValue}
              hoverMode={inHoverMode}
            />
          </MaybeFiltered>
        ))}
      </ContentSection>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  && {
    user-select: none;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  margin-bottom: 1rem;
  padding-right: 1.9rem;
`;

export const Text = styled(DefaultTypography)`
  color: #000000;
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: -0.46px;
  text-transform: capitalize;

  opacity: ${(p: any) => (p?.isHidden ? `0` : `1`)};
  transition: opacity 0.2s linear;
`;

export const ContentSection = styled.div`
  && {
    display: ${(props: { isOpen: boolean }) =>
      props.isOpen ? `flex` : `none`};
    flex-direction: column;
    overflow: hidden;

    && > * {
      margin-top: 1.5rem;
    }
  }
  &&:has(.ql-picker-label) {
    overflow: visible;
  }
`;
