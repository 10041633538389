import React, { useContext, useState } from 'react';
import produce from 'immer';
import { EditorContext } from '@/features/editor/context/editor-context';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { VSpace } from '@/components/spacing';
import { CircularCheckmark } from '@/components/circular-checkmark';
import { SchedulingCard } from '@/features/editor/widgets/targeting/scheduling-card';

export function ExperienceSchedule({
  onlySpecificDates,
}: {
  onlySpecificDates?: boolean;
}) {
  const {
    experienceState: { currentExperience, scheduleExperience },
  } = useContext(EditorContext);
  const [shouldScroll, setShouldScroll] = useState(false);
  const onScheduleExperience = (isSelected: boolean) => {
    if (!isSelected) {
      const updated = produce(currentExperience, (draft) => {
        draft.schedule = undefined;
      });
      scheduleExperience(updated.schedule);
    }
    setIsScheduled(isSelected);
    setShouldScroll(isSelected);
  };
  const [isScheduled, setIsScheduled] = useState(
    currentExperience?.schedule?.type !== undefined,
  );

  return (
    <>
      <GeneralText>4. When do you like to run this experience?</GeneralText>
      <VSpace value={2} />
      <CircularCheckmark
        onChange={(sel) => onScheduleExperience(!sel)}
        selected={!isScheduled}
        caption="Now"
      />
      <VSpace value={2} />
      <CircularCheckmark
        onChange={(sel) => onScheduleExperience(sel)}
        selected={isScheduled}
        caption="I would like to schedule the run"
      />

      {isScheduled ? (
        <>
          <VSpace value={2} />
          <SchedulingCard
            shouldScroll={shouldScroll}
            onlySpecificDates={onlySpecificDates}
          />
        </>
      ) : null}
    </>
  );
}
