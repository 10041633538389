import React, { useContext } from 'react';
import styled from 'styled-components';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';
import { DeviceType } from '@/utils/definitions';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { TitleWrapper } from '@/features/editor/widgets/changelog';
import { EditorContext } from '@/features/editor/context/editor-context';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { VSpace } from '@/components/spacing';
import { TargetingControlsList } from '@/features/editor/widgets/shared/targeting-controls/targeting-controls-list';
import Portal from '@/components/portal';
import { DEVICE_WRAPPER_ID } from '@/features/editor/device-preview';
import { GradientModal } from '@/components/gradient-modal';
import { centered } from '@/components/use-shared-element';
import { MountPointInfoBox } from '@/features/editor/widgets/checkout-extensibility/mount-point-info-box';

export function CheckoutInspectorNoMountPoints() {
  const {
    devicePreview: {
      editorState: { device },
    },
  } = useContext(EditorContext);

  return (
    <InspectorWidgetLayout
      title={
        <TitleWrapper>
          <EditorRenameButton />
          <DescriptionEditButton />
        </TitleWrapper>
      }
      progress={0}
      hideBackButton
      showCloseButton
      footer={null}
    >
      <Wrapper device={device}>
        <GeneralText>
          1. What audience and where will the experience run?
        </GeneralText>
        <VSpace value={2} />
        <TargetingControlsList />
        <VSpace value={3} />
        <GeneralText>
          2. No changes yet, what would you like to add?
        </GeneralText>
        <VSpace value={2} />
        <MountPointInfoBox />
        <VSpace value={2} />
        <Portal selector={`#${DEVICE_WRAPPER_ID}`}>
          <DarkenDevice />
        </Portal>
      </Wrapper>
    </InspectorWidgetLayout>
  );
}

const NoMountPointsContent = () => (
  <>
    <HintText>
      To add additional features to the checkout page in Shopify, you need to
      specify where those features should appear. This is done by defining
      specific areas in the theme checkout editor.
    </HintText>
    <VSpace value={2} />
    <HintText>
      When finished, click the &quot;Refresh&quot; button to see the changes.
    </HintText>
    <VSpace value={2} />
    <div style={{ position: `relative`, paddingTop: `56.25%` }}>
      <Video
        src="https://iframe.mediadelivery.net/embed/173074/8482477f-7e3c-413d-8c74-115cf97d8b98?autoplay=false&loop=false&muted=false&preload=true&responsive=true"
        loading="lazy"
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        // @ts-ignore
        allowFullScreen="true"
      />
    </div>
  </>
);

const MODAL_RECT = centered(57, 70);

export const NoMountPointsModal = ({ fromRef, isVisible, setIsVisible }) => (
  <GradientModal
    showBackdrop
    isVisible={isVisible}
    targetPosAndSize={MODAL_RECT}
    fromRef={fromRef}
    header="How to add more extension points"
    onClose={() => setIsVisible(false)}
    overflowBottom={{ heightInRem: 0, turnPointInPercent: 100 }}
  >
    <VSpace value={2} />
    <NoMountPointsContent />
  </GradientModal>
);

const Wrapper = styled.div`
  && {
    width: 100%;

    padding-right: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `0` : `20rem`};
  }
`;

const HintText = styled.span`
  color: #5b656e;
  font-size: 1.3em;
  letter-spacing: -0.06px;
  line-height: 2rem;
`;

const Video = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
`;

const DarkenDevice = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;
