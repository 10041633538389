import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  size?: string;
  profilePic?: string;
  wrapperStyles?: any;
};
export const Avatar: FC<Props> = ({
  size,
  profilePic,
  children,
  wrapperStyles,
}) => (
  <Wrapper size={size} style={wrapperStyles}>
    {profilePic ? (
      <PicContainer size={size}>
        <img alt="ppic" src={profilePic} />
      </PicContainer>
    ) : (
      children
    )}
  </Wrapper>
);

const PicContainer = styled.div`
  height: ${({ size }: Props) => size || `3.8rem`};
  width: ${({ size }: Props) => size || `3.8rem`};

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  height: ${({ size }: Props) => size || `3.8rem`};
  width: ${({ size }: Props) => size || `3.8rem`};
  background: #dedede;
  border-radius: 50%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &:active {
    opacity: 1;
    cursor: default;
  }
`;
