import { toast } from 'react-toastify';
import { Experience } from '@/webapi/use-experience-api';
import { routes } from '@/webapi/routes';
import { maybe } from '@/features/details/utils';

export const isThemeTestPublishNotAllowed = async (
  currentExperience: Experience,
) => {
  if (!currentExperience.isThemeTest) {
    return { value: false };
  }
  return (
    await fetch(routes.isThemeTestPublishAllowed, {
      method: `POST`,
      credentials: `include`,
      body: JSON.stringify({ value: currentExperience.id }),
    })
  ).json();
};
export const showThemeTestWarningToast = (response) => {
  const name = maybe(() => JSON.parse(response.value).name);
  toast(
    `Please pause the theme test "${name}" before publishing. Only one theme test can run at a time to prevent conflicts.`,
    {
      theme: `colored`,
      autoClose: 10000,
      type: `info`,
    },
  );
};
