import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { GradientLayoutContext } from '@/components/gradient-layout';
import { Flexbox } from '@/components/flex';
import { DefaultTypography } from '@/components/typography';
import { DeviceType } from '@/utils/definitions';
import { VSpace } from '@/components/spacing';
import { Card } from '@/components/card';
import { ScheduleType } from '@/webapi/use-experience-api';
import { DatesAndTimesPicker } from '@/features/editor/widgets/targeting/schedule-time-picker';
import { EditorContext } from '@/features/editor/context/editor-context';

export interface SchedulingCardProps {
  shouldScroll: boolean;
  onlySpecificDates?: boolean;
}

export function SchedulingCard({
  shouldScroll,
  onlySpecificDates,
}: SchedulingCardProps) {
  const { experienceState } = useContext(EditorContext);
  const { currentExperience: experience } = experienceState;
  const { schedule } = experience;

  const [scheduleType, setScheduleType] = useState(
    schedule?.type || ScheduleType.SPECIFIC_DATE,
  );
  const { scrollToEnd } = useContext(GradientLayoutContext);

  useEffect(() => {
    if (shouldScroll) {
      scrollToEnd();
    }
  }, [shouldScroll]);

  return (
    <Wrapper>
      <Header />
      <VSpace value={2} />
      <SchedulingOptionsWrapper>
        <ScheduleOption
          focused={scheduleType === ScheduleType.SPECIFIC_DATE}
          onClick={() => setScheduleType(ScheduleType.SPECIFIC_DATE)}
          text="Specific date"
        />
        <ScheduleOption
          isDisabled={onlySpecificDates}
          focused={scheduleType === ScheduleType.EVERY_DAY_AT_A_CERTAIN_TIME}
          onClick={() =>
            !onlySpecificDates &&
            setScheduleType(ScheduleType.EVERY_DAY_AT_A_CERTAIN_TIME)
          }
          text="Every day at a certain time"
        />
        <ScheduleOption
          isDisabled={onlySpecificDates}
          focused={scheduleType === ScheduleType.EVERY_WEEK_ON_A_CERTAIN_DAY}
          onClick={() =>
            !onlySpecificDates &&
            setScheduleType(ScheduleType.EVERY_WEEK_ON_A_CERTAIN_DAY)
          }
          text="Every week on a certain day"
        />
      </SchedulingOptionsWrapper>
      <VSpace value={0.5} />
      <RulesHeader>Rules</RulesHeader>
      <VSpace value={0.5} />
      <DatesAndTimesPicker type={scheduleType} />
    </Wrapper>
  );
}

function Header() {
  return (
    <Flexbox width="100%" direction="row" justify="start">
      <HeaderText>When would you like to schedule the run</HeaderText>
    </Flexbox>
  );
}

function ScheduleOption({
  focused,
  onClick,
  text,
  isDisabled,
}: {
  focused: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  text: string;
}) {
  return (
    <OptionChip isDisabled={isDisabled} focused={focused} onClick={onClick}>
      {text}
      {focused && <SelectedOption />}
    </OptionChip>
  );
}

function SelectedOption() {
  return (
    <StyledIcon>
      <StaticImage
        src="../../../../assets/approve-blue.svg"
        alt=""
        placeholder="none"
        width={14}
        objectFit="contain"
      />
    </StyledIcon>
  );
}

const Wrapper = styled(Card)`
  && {
    width: 100%;
    padding: ${({ device }: { device: DeviceType }) =>
      device === DeviceType.Desktop ? `2rem` : `3rem`};
    cursor: default;
  }
`;

const HeaderText = styled(DefaultTypography)`
  && {
    display: flex;
    color: #000000;
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

const RulesHeader = styled(DefaultTypography)`
  && {
    display: flex;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const SchedulingOptionsWrapper = styled.ul`
  && {
    margin: 0;
    padding: 0 0 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 56.8rem;
    list-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    && > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

interface P {
  focused: boolean;
  isDisabled?: boolean;
}

const OptionChip = styled.li`
  && {
    cursor: pointer;
    width: auto;
    opacity: ${({ focused, isDisabled }: P) =>
      // eslint-disable-next-line no-nested-ternary
      isDisabled ? `0.3` : focused ? `1` : `0.5`};
    padding: 0.8rem 1.2rem;
    border: 1.5px solid ${({ focused }: P) => (focused ? `#0086FF` : `#f2f4f6`)};
    border-radius: 1.8rem;
    user-select: none;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: Inter, serif;
    color: ${({ focused }: P) => (focused ? `#0086FF` : `#58606c`)};
    background: #f2f4f6;
    transition: opacity 0.2s ease-out;
    position: relative;
    :hover {
      opacity: ${({ isDisabled }: P) => (isDisabled ? `0.3` : `0.8`)};
    }

    :active {
      opacity: ${({ isDisabled }: P) => (isDisabled ? `0.3` : `0.6`)};
    }
  }
`;

const StyledIcon = styled.div`
  .gatsby-image-wrapper {
    position: absolute;
    right: -0.2rem;
    top: 1.8rem;
  }
`;
