import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  toTransformKind,
  useTranspiler,
} from '@/features/editor/context/use-transpiler';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
} from '@/webapi/use-experience-api';
import {
  AnchorOrigin,
  NewElementMessage,
} from '@/features/editor/context/use-device-preview';
import { EditorContext } from '@/features/editor/context/editor-context';
import { VSpace } from '@/components/spacing';
import { CatalogApp, CatalogWidget } from '@/webapi/use-widget-catalog-api';
import { CustomWidgetStep } from '@/features/editor/widgets/custom-widget/shared/context';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { CatalogAppsList } from '@/features/editor/widgets/shared/apps-catalog/apps-list';
import { TEMP_NO_SELECTOR } from '@/features/editor/widgets/changelog/placeholder';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { PageRedirectChange, ThemeChange } from '@/pkg/sdk';
import { useCodeBlocksSelect } from '@/features/editor/widgets/code-editors/use-code-blocks-select';

export function CatalogAppSelector({
  kind,
  selector,
  isInCart,
}: NewElementMessage) {
  const { applyTempChange, inspectorNav, devicePreview } =
    useContext(EditorContext);
  const { anchor } = devicePreview;
  const { gotoChangelog } = inspectorNav;
  const { asNewContentChange } = useTranspiler();
  const [newChange, setNewChange] = useState<EditorDeclarativeBlock>(undefined);
  const [id] = useState(`_loomi_addon_${new Date().getTime()}`);

  useEffect(() => {
    const addition = asNewContentChange(
      id,
      toTransformKind(kind),
      selector,
      true,
      true,
      isInCart,
    );
    setNewChange(addition);
    if (selector === TEMP_NO_SELECTOR) {
      anchor(`#vsly-invalid-selector`, AnchorOrigin.NEW_ELEMENT);
    } else {
      applyTempChange(addition);
      setTimeout(() => anchor(`#${id}`, AnchorOrigin.NEW_ELEMENT), 200);
    }
  }, [id]);

  return (
    <InspectorWidgetLayout
      title="1. What would you like to add?"
      backCaption="< Back to changes"
      progress={20}
      onBackClicked={gotoChangelog}
    >
      <CatalogAppSelectorList id={id} newChange={newChange} />
      <VSpace value={8} />
    </InspectorWidgetLayout>
  );
}

interface CatalogAppSelectorListProps {
  newChange: EditorDeclarativeBlock;
  id: string;
  longTiles?: boolean;
}

export function CatalogAppSelectorList({
  newChange,
  id,
  longTiles,
}: CatalogAppSelectorListProps) {
  const {
    resources,
    loadedApps,
    devicePreview,
    applyTempChange,
    inspectorNav: {
      gotoCustomWidget,
      gotoFakeClick,
      gotoCodeTypeSelector,
      gotoPageRedirectWidget,
      gotoThemeTest,
    },
  } = useContext(EditorContext);

  const onCodeBlock = () => gotoCodeTypeSelector(newChange);

  const onFakeClick = useCallback(() => {
    if (newChange) {
      gotoFakeClick(newChange);
    }
  }, [id, newChange]);

  const onCustomWidget = async (
    app: CatalogApp,
    setLoading?: (b: boolean) => void,
  ) => {
    if (newChange) {
      devicePreview.downloadAppWidgets(app);
      if (!loadedApps.current.includes(app.id)) {
        showLoader();
        setLoading && setLoading(true);
        await new Promise((resolve) => {
          setTimeout(resolve, 300);
        });
        setLoading && setLoading(false);
        hideLoader();
        loadedApps.current = [...loadedApps.current, app.id];
      }
      applyTempChange(newChange);
      await gotoCustomWidget(newChange, CustomWidgetStep.SELECT, app);
    }
  };

  const { continueToCompoundEditor, onCodeBlockSelected } = useCodeBlocksSelect(
    {
      origChange: newChange,
    },
  );

  const onCustomCodeComponent = (w: CatalogWidget) => {
    if (w.id === `custom`) {
      continueToCompoundEditor();
    } else {
      onCodeBlockSelected(w);
    }
  };

  const onPageRedirect = useCallback(() => {
    if (newChange) {
      newChange.block = {
        kind: `pageRedirect`,
        selector: `div`,
        value: {
          redirectAfter: 1,
          destUrl: ``,
        } as PageRedirectChange,
      };
      newChange.initialHtml = undefined;
      newChange.editorKind = EditorChangeKind.NEW_COMPONENT;
      gotoPageRedirectWidget(newChange);
    }
  }, [id, newChange]);

  const onThemeTest = useCallback(() => {
    if (newChange) {
      newChange.block = {
        kind: `themeTest`,
        selector: `div`,
        value: {
          targetThemeId: 0,
        } as ThemeChange,
      };
      newChange.initialHtml = undefined;
      newChange.editorKind = EditorChangeKind.NEW_COMPONENT;
      gotoThemeTest(newChange);
    }
  }, [id, newChange]);

  return (
    <CatalogAppsList
      appsCatalog={resources.appsCatalog}
      onCustomWidget={onCustomWidget}
      onFakeClick={onFakeClick}
      onCodeBlock={onCodeBlock}
      onThemeTest={onThemeTest}
      onPageRedirect={onPageRedirect}
      onCustomCodeComponent={onCustomCodeComponent}
      longTiles={longTiles}
    />
  );
}
