import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TbTagsOff } from 'react-icons/tb';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import {
  SettingCardButton,
  SettingsCard,
  SettingsCardText,
  SettingsCardTitle,
} from '@/features/shop-settings/settings-card';
import { HSpace } from '@/components/spacing';
import { Label, LabelsList } from '@/components/labels-list';
import {
  ProductExcludedTags as ProductExcludedTagsType,
  useStoreSettingsApi,
} from '@/webapi/use-store-settings-api';
import { AccountContext } from '@/features/account-context';

export function ExcludedTagsCard() {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);

  const { setProductExcludedTags, getProductExcludedTags, loading } =
    useStoreSettingsApi();

  const [tags, setTags] = useState<ProductExcludedTagsType['tags']>([]);
  const [initialTags, setInitialTags] = useState<
    ProductExcludedTagsType['tags']
  >([]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useAsyncEffect(async () => {
    try {
      const result = await getProductExcludedTags();
      if (result && Array.isArray(result.tags)) {
        setTags(result.tags);
        setInitialTags(result.tags);
      } else {
        setTags([]);
        setInitialTags([]);
      }
    } catch (error) {
      setTags([]);
      setInitialTags([]);
    }
  }, [alias]);

  useEffect(() => {
    setHasUnsavedChanges(JSON.stringify(tags) !== JSON.stringify(initialTags));
  }, [tags, initialTags]);

  const onNewTag = async (tag: string) => {
    const trimmedTag = tag.trim();
    if (trimmedTag && !tags.includes(trimmedTag)) {
      setTags((prev) => [...prev, trimmedTag]);
    }
  };

  const onRemoveTag = async (tagToDelete: string) => {
    setTags((prev) => prev.filter((tag) => tag !== tagToDelete));
  };

  const onSaveChanges = async () => {
    if (!hasUnsavedChanges) return;
    const result = await setProductExcludedTags(tags);
    if (result && Array.isArray(result.tags)) {
      setTags(result.tags);
      setInitialTags(result.tags);
    }
    setHasUnsavedChanges(false);
  };

  const transformedTags: Label[] = useMemo(
    () => tags.map((tag) => ({ label: tag, isSelected: true })),
    [tags],
  );

  return (
    <SettingsCard loading={loading}>
      <SettingsCardTitle>
        <TbTagsOff size={20} />
        <HSpace value={1.5} />
        Excluded Product Tags
      </SettingsCardTitle>
      <SettingsCardText>
        Products with the following tags will be excluded from all
        recommendations and upsells globally.
      </SettingsCardText>
      <LabelsList
        labels={transformedTags}
        collapseAfter={20}
        alwaysSelected
        allowAppend
        appendText="+ ADD TAG"
        onAddLabel={onNewTag}
        onRemoveLabel={onRemoveTag}
      />
      <ButtonWrapper>
        {hasUnsavedChanges && (
          <SettingCardButton small black onClick={onSaveChanges}>
            Save Changes
          </SettingCardButton>
        )}
      </ButtonWrapper>
    </SettingsCard>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  width: auto;
  margin-top: -3rem;
`;
