import { StrapiTextualProps } from '@/features/strapi/components/textual';

export const CustomersContent = {
  title: {
    size: `5.2rem`,
    weight: `bold`,
    text: `Powering the most innovative\nShopify brands`,
    mobileText: `Powering the most\n innovative\nShopify brands`,
    mobileMultiplier: 0.75,
    smMobileMultiplier: 0.68,
  } as StrapiTextualProps,
  subtitle: {
    lineHeight: `1.8`,
    size: `2rem`,
    weight: `normal`,
    mobileMultiplier: 0.85,
    text: `A/B test, Optimize, and Personalize every step of the way.
    Zero code, a ton of sales.`,
    mobileText: `A/B test, Optimize, and Personalize every step.
    Zero code, a ton of sales.`,
  } as StrapiTextualProps,
  caseStudiesTitle: {
    size: `5.2rem`,
    weight: `bold`,
    text: `Check our customer success stories`,
    mobileText: `Check our customer\n success stories`,
    mobileMultiplier: 0.75,
    smMobileMultiplier: 0.68,
  } as StrapiTextualProps,
  caseStudies: [
    {
      title: `Underoutfit boosts CVR by 42%`,
      link: `/public/case-studies/underoutfit`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hub/143410811/hubfs/1-2.png?width=700&name=1-2.png`,
    },
    {
      title: `The Perfect Jeans increased CVR by 18%`,
      link: `/public/case-studies/tpj`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hub/143410811/hubfs/2-4.png?width=700&name=2-4.png`,
    },
    {
      title: `Canopy: Increased CVR by 33%`,
      link: `/public/case-studies/canopy`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/Artboard.png`,
    },
    {
      title: `Faherty: Increased Revenue per session by 18%`,
      link: `/public/case-studies/faherty`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hubfs/143410811/Artboard%20Copy%2029.png`,
    },
    {
      title: `Nectarlife increased Revenue by 30%`,
      link: `/public/case-studies/nectar`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hub/143410811/hubfs/4.png?width=700&name=4.png`,
    },
    {
      title: `Kidoriman increased CVR by 76%`,
      link: `/public/case-studies/kidoriman`,
      image: `https://143410811.fs1.hubspotusercontent-eu1.net/hub/143410811/hubfs/3.png?width=700&name=3.png`,
    },
  ],
};

export const CaseStudiesPages = {
  'The Perfect Jean': `https://143410811.hs-sites-eu1.com/en/t_casestudy_visually-tpj`,
  Underoutfit: `https://143410811.hs-sites-eu1.com/en/underoutfit_casestudy_visually`,
  Kidoriman: `https://143410811.hs-sites-eu1.com/en/kidoriman_casestudy_visually-1-0`,
  'Nectar Life': `https://143410811.hs-sites-eu1.com/en/nectar_casestudy_visually-1`,
  Canopy: `https://143410811.hs-sites-eu1.com/canopy`,
  Faherty: `https://143410811.hs-sites-eu1.com/faherty`,
};
