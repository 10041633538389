import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { Experience } from '@/webapi/use-experience-api';
import { maybe } from '@/features/details/utils';

export enum Pages {
  HOME = `/`,
  DASHBOARD = `/dashboard`,
  EDITOR = `/editor`,
  EXPERIENCE_DETAILS = `/details`,
  LOGIN = `/login/`,
  PLEASE_REINSTALL = `/please-reinstall`,
  SIGN_UP = `/signup`,
  WELCOME = `/welcome`,
  AWAIT_EMAIL = `/await-email`,
  ACCOUNT_SETTINGS = `/account-settings`,
  SHOP_SETTINGS = `/shop-settings`,
  TEAMMATES = `/teammates`,
  ACCOUNTS = `/accounts`,
  THEMES = `/themes`,
  INTEGRATIONS = `/integrations`,
  GLOBAL_RULES = `/global-rules`,
}

export function editorRoute(expId?: string) {
  if (expId) {
    return `${Pages.EDITOR}?expId=${expId}`;
  }
  return `${Pages.EDITOR}`;
}

export function editorRouteWithWidget(app: CatalogApp) {
  return `${Pages.EDITOR}?app=${app.id}`;
}

export function editorRouteWithAudience(audienceId: string) {
  return `${Pages.EDITOR}?audience=${audienceId}`;
}

export function detailsRoute(exp: Experience, audience = ``) {
  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, `0`);
    const day = String(date.getDate()).padStart(2, `0`);
    return `${year}-${month}-${day}`;
  }
  const startDate = maybe(() => formatDate(new Date(exp.metricsStartAt)));
  let route = `${Pages.EXPERIENCE_DETAILS}?expId=${exp.id}`;

  if (startDate) {
    route = `${route}&s=${startDate}`;
  }
  if (exp.sessionType) {
    route = `${route}&st=${exp.sessionType}`;
  }
  if (audience) {
    route = `${route}&aud=${audience}`;
  }
  return route;
}
