import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import md5 from 'blueimp-md5';
import { toast } from 'react-toastify';
import { css } from 'styled-components';
import produce from 'immer';
import { mutateFn, useComplexState } from '@/utils/use-complex-state';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PostPurchaseProps } from '@/features/editor/widgets/post-purchase/props';
import { INITIAL_POST_PURCHASE_PROPS } from '@/features/editor/widgets/post-purchase/initial-state';
import { useOfferProducts } from '@/features/editor/widgets/post-purchase/use-offers';
import {
  EDITOR_POST_PURCHASE_CACHE,
  hashObject,
  useCachedAutoSave,
} from '@/components/hooks/use-cached-auto-save';
import { isBrowser } from '@/utils/browser';
import { useWidgetControls } from '@/features/editor/widgets/shared/use-widget-controls';
import { usePublishModal } from '@/features/editor/use-publish-modal';
import { useShopifyApi } from '@/webapi/use-shopify-api';
import {
  AdvancedRulingOption,
  RecommendationOptions,
  RecommendationType,
} from '@/webapi/use-widget-catalog-api';
import { routes } from '@/webapi/routes';
import { AccountContext } from '@/features/account-context';
import { QBItemSelection } from '@/components/query-builder/models';
import { Experience } from '@/webapi/use-experience-api';

export const PostPurchaseContext = React.createContext({} as PostPurchaseCtx);

const DEFAULT_COND = [
  {
    qbProps: {
      envKey: `inventory`,
      kind: `NUMERIC_VALUE`,
      caption: `Inventory`,
      disableOrCond: true,
      defaultNumericValue: 1,
      defaultNumericValueOp: `gt`,
    },
    values: [{ op: `gte`, value: `0` }],
  },
  {
    qbProps: {
      envKey: `variantPerProduct`,
      kind: `MULTI_VALUE`,
      caption: `Show Variants Per Product`,
      disableOrCond: true,
      disableOp: true,
      options: [
        { key: `yes`, value: `yes` },
        { key: `no`, value: `no` },
      ],
    },
    values: [{ op: `is`, value: `no` }],
  },
];

export interface PostPurchaseCtx {
  hasChanges: boolean;
  isReviewEnabled: boolean;
  props: PostPurchaseProps;
  setProps: (fn: mutateFn<PostPurchaseProps>) => void;
  currentStep: PostPurchaseStep;
  gotoCustomize: () => void;
  gotoStyle: () => void;
  gotoReview: () => void;
  onSave: () => void;
  onPublish: () => void;
  publishButtonRef: React.MutableRefObject<HTMLElement | null>;
  publishModalProps: any;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  setOfferStrategy: (offerNum: number, strategy: RecommendationType) => void;
  updateOfferStrategyCondition: (
    offerNum: number,
    condition: Array<QBItemSelection>,
  ) => void;
  updateOfferStrategyOptions: (
    offerNum: number,
    options: RecommendationOptions,
  ) => void;
  updateOfferAdvancedRulingOptions: (
    offerNum: number,
    options: Array<AdvancedRulingOption>,
  ) => void;
}

export enum PostPurchaseStep {
  CUSTOMIZE,
  STYLE,
  REVIEW = 2,
}

export function newPostPurchaseContext(
  cachedProps: PostPurchaseProps | undefined,
): PostPurchaseCtx {
  const {
    account: {
      store: { alias, currency },
    },
  } = useContext(AccountContext);
  const {
    experienceState: { setPostPurchaseProps, currentExperience },
    devicePreview: { updateWidgetProps },
  } = useContext(EditorContext);

  const { getPostPurchaseStatus } = useShopifyApi();

  const upgradeOldProps = (
    p?: PostPurchaseProps,
  ): PostPurchaseProps | undefined => {
    if (!p) return undefined;
    return produce(p, (draft) => {
      if (!draft.style.callout) {
        draft.style.callout = INITIAL_POST_PURCHASE_PROPS.style.callout;
      }

      if (!draft.style.disclaimer) {
        draft.style.disclaimer = INITIAL_POST_PURCHASE_PROPS.style.disclaimer;
      }

      if (draft.settings.addTaxToFinalPrice === undefined) {
        draft.settings.addTaxToFinalPrice = false;
      }
      if (!draft.currencyCode) {
        draft.currencyCode = currency;
      }
    });
  };

  const [props, setProps] = useComplexState<PostPurchaseProps>(
    upgradeOldProps(cachedProps) ||
      upgradeOldProps(currentExperience?.postPurchaseProps) ||
      INITIAL_POST_PURCHASE_PROPS,
  );

  useEffect(() => {
    if (currency && props.currencyCode !== currency) {
      setProps((prevProps) => ({
        ...prevProps,
        currencyCode: currency,
      }));
    }
  }, [currency, setProps]);

  const [lastSavedExperienceHash, setLastSavedExperienceHash] = useState(
    hashExperience(currentExperience),
  );

  const [shouldSave, setShouldSave] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const hasChanges = useMemo(
    () =>
      hashProps(currentExperience.postPurchaseProps) !== hashProps(props) ||
      hashExperience(currentExperience) !== lastSavedExperienceHash,
    [props, currentExperience],
  );

  useEffect(() => {
    if (currentExperience?.postPurchaseProps && shouldSave) {
      controlsProps.onSave().then(() => setShouldSave(false));
      setLastSavedExperienceHash(hashExperience(currentExperience));
    }
  }, [currentExperience?.postPurchaseProps]);

  const onSave = async () => {
    setPostPurchaseProps(props);
    setShouldSave(true);
  };

  const isReviewEnabled = useMemo(
    () => true,
    [
      hasChanges,
      currentExperience.postPurchaseProps,
      props,
      lastSavedExperienceHash,
    ],
  );

  const publishButtonRef = useRef(null);
  const { controlsProps } = useWidgetControls(() => undefined, ``);
  const publishModalProps = usePublishModal(publishButtonRef);

  useCachedAutoSave(props, currentExperience.id, EDITOR_POST_PURCHASE_CACHE);
  const [currentStep, setCurrentStep] = useState(PostPurchaseStep.CUSTOMIZE);
  useOfferProducts(props, setProps);

  const gotoCustomize = () => setCurrentStep(PostPurchaseStep.CUSTOMIZE);
  const gotoStyle = () => setCurrentStep(PostPurchaseStep.STYLE);
  const gotoReview = () => setCurrentStep(PostPurchaseStep.REVIEW);

  useEffect(() => {
    if (props?.offers?.length > 0) {
      updateWidgetProps(``, props);
    }
  }, [props]);

  useEffect(() => {
    isBrowser() &&
      window.addEventListener(`post-purchase-reload`, loadedHandler);
    return () =>
      isBrowser() &&
      window.removeEventListener(`post-purchase-reload`, loadedHandler);
  }, [props]);

  const loadedHandler = () => {
    updateWidgetProps(``, props);
  };

  const onPublish = () => {
    if (hasChanges) {
      toast(`You have unsaved changes, please save them before publish`, {
        theme: `colored`,
        type: `warning`,
        className: css({
          fontFamily: `JetBrains Mono, Serif`,
          fontWight: `700`,
        }),
      });
      return;
    }
    getPostPurchaseStatus(true).then((status) => {
      if (status) {
        publishModalProps.show();
      } else {
        setIsModalVisible(true);
      }
    });
  };

  const setOfferStrategy = (offerNum: number, strategy: RecommendationType) => {
    setProps((draft) => {
      const { loadingEnv } = draft.offers[offerNum];
      const prevType = loadingEnv?.recommendationOptions?.type;
      draft.offers[offerNum].loadingStrategy = strategy;

      if (!!loadingEnv && !!loadingEnv.recommendationOptions) {
        loadingEnv.recommendationOptions.type = strategy;
        if (strategy !== prevType) {
          // @ts-ignore
          loadingEnv.recommendationOptions.condition = DEFAULT_COND;
          loadingEnv.recommendationOptions.conditionId = md5(
            JSON.stringify(DEFAULT_COND),
          );
        }
      } else {
        draft.offers[offerNum].loadingEnv = {
          recommendationOptions: {
            appId: `85820b0aef951499c8b1d3bd0fbd75912`,
            widgetContext: {
              widgetId: `85820b0aef951499c8b1d3bd0fbd75912`,
              widgetVersion: `_20`,
              atcEnabled: true,
            },
            env: routes.getEnv(),
            type: strategy,
            storeAlias: alias,
            condition: DEFAULT_COND,
            conditionId: md5(JSON.stringify(DEFAULT_COND)),
          } as RecommendationOptions,
        };
      }
    });
  };

  const updateOfferStrategyOptions = (
    offerNum: number,
    options: RecommendationOptions,
  ) => {
    setProps((draft) => {
      if (hasNoLoadingEnv(draft, offerNum)) {
        draft.offers[offerNum].loadingEnv = {};
      }
      draft.offers[offerNum].loadingEnv.recommendationOptions = {
        ...draft.offers[offerNum]?.loadingEnv?.recommendationOptions,
        ...options,
      };
    });
  };

  const updateOfferStrategyCondition = (
    offerNum: number,
    condition: Array<QBItemSelection>,
  ) => {
    setProps((draft) => {
      if (hasNoLoadingEnv(draft, offerNum)) {
        draft.offers[offerNum].loadingEnv = {};
      }

      draft.offers[offerNum].loadingEnv = {
        recommendationOptions: {
          type: draft?.offers?.[offerNum].loadingStrategy,
          ...draft?.offers?.[offerNum]?.loadingEnv?.recommendationOptions,
          condition,
          conditionId: md5(JSON.stringify(condition)),
          env: routes.getEnv(),
        },
      };
    });
  };

  const updateOfferAdvancedRulingOptions = (
    offerNum: number,
    options: Array<AdvancedRulingOption>,
  ) => {
    setProps((draft) => {
      if (hasNoLoadingEnv(draft, offerNum)) {
        draft.offers[offerNum].loadingEnv = {};
      }

      if (
        draft.offers[offerNum].loadingEnv?.recommendationOptions?.advancedRuling
          ?.length > 0
      ) {
        draft.offers[offerNum].loadingEnv.recommendationOptions.advancedRuling =
          options;
      } else {
        draft.offers[offerNum].loadingEnv.recommendationOptions = {
          type: RecommendationType.ADVANCED_RULING,
          env: routes.getEnv(),
          appId: `upsells`,
          ...draft.offers[offerNum].loadingEnv?.recommendationOptions,
          advancedRuling: options,
        };
      }
    });
  };

  return {
    hasChanges,
    isReviewEnabled,
    props,
    setProps,
    currentStep,
    gotoCustomize,
    gotoStyle,
    gotoReview,
    onSave,
    onPublish,
    publishButtonRef,
    publishModalProps,
    isModalVisible,
    setIsModalVisible,
    setOfferStrategy,
    updateOfferStrategyOptions,
    updateOfferStrategyCondition,
    updateOfferAdvancedRulingOptions,
  };
}

function hashProps(props: PostPurchaseProps): string {
  const filteredObj = {
    settings: props?.settings,
    style: props?.style,
    content: props?.content,
    offers: props?.offers?.map((offer) => ({
      discount: offer?.discountPercentage,
      loading: offer?.loadingEnv,
      strategy: offer?.loadingStrategy,
    })),
  };
  return hashObject(filteredObj);
}

function hasNoLoadingEnv(draft: any, offerNum: number) {
  return typeof draft.offers[offerNum].loadingEnv === `undefined`;
}

function hashExperience(currentExperience: Experience) {
  return hashObject({
    name: currentExperience.name,
    variants: currentExperience.variants,
    targeting: currentExperience.targeting,
    schedule: currentExperience.schedule,
  });
}
