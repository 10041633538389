import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Chip } from '@/components/chip';
import { OverflowBottom, OverflowTop } from '@/components/overflow';

export interface Label {
  label: string;
  isSelected: boolean;
}

export interface LabelsListProps {
  labels: Label[];
  allowAppend?: boolean;
  collapseAfter?: number;
  maxHeight?: string;

  onAddLabel?: (label: string) => Promise<void>;
  onRemoveLabel?: (label: string) => Promise<void>;
  onLabelSelection?: (selectedLabels: string[]) => Promise<void>;

  appendText?: string;
  alwaysSelected?: boolean;
}

export function LabelsList({
  labels,
  allowAppend,
  collapseAfter,
  maxHeight,
  onAddLabel,
  onRemoveLabel,
  onLabelSelection,
  appendText,
  alwaysSelected,
}: LabelsListProps) {
  const [labelsList, setLabelsList] = useState(
    labels?.sort((a, b) => {
      if (a.isSelected !== b.isSelected) {
        return a.isSelected ? -1 : 1;
      }
      return a.label.localeCompare(b.label);
    }),
  );

  useEffect(() => {
    setLabelsList(
      labels?.sort((a, b) => {
        if (a.isSelected !== b.isSelected) {
          return a.isSelected ? -1 : 1;
        }
        return a.label.localeCompare(b.label);
      }),
    );
  }, [labels]);

  const [isExpanded, setIsExpanded] = useState(false);

  const expandedLabels = useMemo(
    () => labelsList.slice(0, collapseAfter || labelsList.length),
    [labelsList, collapseAfter],
  );

  const collapsedLabels = useMemo(() => {
    if (collapseAfter) {
      return labels.slice(collapseAfter);
    }
    return [];
  }, [labelsList, collapseAfter]);

  const shownLabels = useMemo(() => {
    if (isExpanded) {
      return [...expandedLabels, ...collapsedLabels];
    }
    return expandedLabels;
  }, [isExpanded, expandedLabels, collapsedLabels]);

  const showExpandButton = useMemo(
    () => collapsedLabels?.length > 0 && !isExpanded,
    [collapsedLabels, isExpanded],
  );

  const onExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const onNewLabel = async (newText: string) => {
    const exists = labelsList.find((l) => l.label === newText);
    if (!exists) {
      await onAddLabel?.(newText);
      setLabelsList((old: Label[]) => [
        { label: newText, isSelected: true },
        ...old,
      ]);
    }
  };

  const onSelection = async (label: string, selected: boolean) => {
    const newList = labelsList.map((l) => {
      if (l.label === label) {
        l.isSelected = selected;
      }
      return l;
    });
    await onLabelSelection?.(
      newList.filter((l) => l.isSelected).map((l) => l.label),
    );
    setLabelsList(newList);
  };

  const onRemove = async (label: string) => {
    const exists = labelsList.find((l) => l.label === label);
    if (exists) {
      await onRemoveLabel?.(label);
      setLabelsList((old: Label[]) => old.filter((l) => l.label !== label));
    }
  };

  return (
    <Wrapper maxHeight={maxHeight}>
      {isExpanded && <OverflowTop height="1.5rem" turnPoint="10%" />}
      <ScrollArea isExpanded>
        {allowAppend && (
          <Chip mode="editable" onEdit={onNewLabel} clearAfterEdit>
            {appendText || `+ ADD CUSTOM LABEL`}
          </Chip>
        )}
        {shownLabels.map((l) => (
          <Chip
            key={l.label}
            mode={`${alwaysSelected ? `removable` : `selectable-removable`}`}
            payload={l.label}
            isSelected={alwaysSelected ? false : l.isSelected}
            onRemove={onRemove}
            onChange={(sel) => onSelection(l.label, sel)}
          >
            {l.label}
          </Chip>
        ))}
        {showExpandButton && (
          <ExpandButton onClick={onExpand}>
            +{collapsedLabels?.length} Labels
          </ExpandButton>
        )}
      </ScrollArea>
      {isExpanded && <OverflowBottom turnPoint="70%" />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ScrollArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;

  max-height: ${(props) => props.maxHeight || `15rem`};
  overflow-y: ${(props) => (props.isExpanded ? `scroll` : `hidden`)};
  overflow-x: hidden;

  padding-bottom: 4rem;
`;

const ExpandButton = styled.button`
  outline: none;
  appearance: none;
  border: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.35);

  height: fit-content;
  width: fit-content;
  padding: 0.8rem 0;

  font-size: 1.4rem;
  font-family: Inter, serif;
  text-transform: uppercase;
`;
