import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageLayout } from '@/components/page-layout';
import { AccountContext } from '@/features/account-context';
import { storeDomain } from '@/features/shop-settings';
import { ExcludedTagsCard } from '@/features/global-rules/excluded-tags-card';

export function GlobalRulesPage() {
  const ctx = useContext(AccountContext);
  const domain = storeDomain(ctx);

  return (
    <PageLayout hideBackButton={false} heading={`${domain} Global Rules`}>
      <Container>
        <ExcludedTagsCard />
      </Container>
    </PageLayout>
  );
}

export const Container = styled.div`
  width: 68%;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
`;
