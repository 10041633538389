import styled from 'styled-components';

export const NewExperienceButton = styled.button`
  appearance: none;
  outline: none;
  text-decoration: none;

  cursor: pointer;

  background: #17191a;

  font-size: 1.2rem;
  border: none;
  border-radius: 2rem;
  padding: 1rem 2.3rem;
  color: white;

  font-family: Inter, serif;
  font-weight: 500;

  transition: background-color 0.5s ease-out;

  :hover {
    background-color: #555555;
  }

  :active {
    background-color: #666666;
  }
`;
