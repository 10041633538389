import produce from 'immer';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import {
  CatalogApp,
  CatalogWidgetProps,
  Component,
  Customization,
  CustomizationSpec,
} from '@/webapi/use-widget-catalog-api';
import { WidgetChange } from '@/pkg/sdk';
import { schemaToEnv } from '@/features/editor/widgets/custom-widget/env';

/**
 * Takes an old widget change and return a duplicate of that widget setting
 * but migrated to the new version of the widget.
 * The new widget doesn't guarantee to be the same as the old one since some changes are not backwards compatible,
 * but it tries to keep as much as possible of the old settings.
 * @param oldChange the old change to upgrade
 * @param appsCatalog the recent apps catalog to use to upgrade the widget
 * @returns a duplicate of the old widget change but upgraded to the latest version
 */
export function upgradeWidgetChange(
  oldChange: EditorDeclarativeBlock,
  appsCatalog: CatalogApp[],
): EditorDeclarativeBlock | undefined {
  if (oldChange?.block?.kind === `widget` && oldChange?.widgetProps?.appId) {
    const blockValue = oldChange.block.value as WidgetChange;
    const oldWidgetId = blockValue.widgetId;

    const newWidget = appsCatalog
      .flatMap((app) => app.widgets)
      .find((widget) => widget.id === oldWidgetId);

    if (newWidget) {
      const oldEnv = blockValue.env;
      const oldSchema = oldChange.widgetProps;
      const newSchema = JSON.parse(
        JSON.stringify(newWidget.blueprint.schema),
      ) as CatalogWidgetProps;

      newSchema?.customizations?.forEach((cust, custIdx) => {
        cust?.components?.forEach((comp, compIdx) => {
          comp?.specs?.forEach((newSpec, specIdx) => {
            const { spec: oldSpec, customization: oldCust } = findOldWidgetSpec(
              oldSchema,
              cust.key,
              comp.key,
              newSpec.key,
            );

            if (oldSpec) {
              if (isUpgradeToRichText(oldSpec, newSpec)) {
                upgradeToRichText(
                  newSchema,
                  custIdx,
                  compIdx,
                  specIdx,
                  oldCust,
                  oldSpec,
                );
                return;
              }

              Object.entries(oldSpec?.values).forEach(([key, value]) => {
                if (key in newSpec.values) {
                  newSchema.customizations[custIdx].components[compIdx].specs[
                    specIdx
                  ].values[key] = value;
                }
              });
            }
          });
        });
      });

      newSchema.settings?.general?.specs?.forEach((newSpec, specIdx) => {
        const oldSpec = oldSchema.settings.general.specs.find(
          (spec) => spec.key === newSpec.key,
        );

        if (oldSpec) {
          newSchema.settings.general.specs[specIdx].value = oldSpec.value;
        }
      });

      const hasMatchRecsValue = newSchema?.settings?.loading?.options?.find(
        (val) => val.value.type === oldSchema.settings.loading.value.value.type,
      );
      if (hasMatchRecsValue) {
        newSchema.settings.loading.value.value =
          oldSchema.settings.loading.value.value;

        newSchema.settings.loading.loadingEnv = {
          ...oldSchema.settings.loading.loadingEnv,
        };
      }

      const newEnv = schemaToEnv(newSchema, oldEnv[`sectionId`]);
      return produce(oldChange, (draft) => {
        draft.widgetProps = newSchema;
        (draft.block.value as WidgetChange).env = newEnv;
        (draft.block.value as WidgetChange).version = newWidget.version;
      });
    }
  }

  return undefined;
}

function upgradeToRichText(
  newSchema: CatalogWidgetProps,
  custIdx: number,
  compIdx: number,
  specIdx: number,
  oldCust: Customization,
  oldSpec: CustomizationSpec,
) {
  const currentSpec =
    newSchema.customizations[custIdx].components[compIdx].specs[specIdx];
  const fontSpec = findSpecWithKey(oldCust, `font`);
  const richTextAttributes = currentSpec.values[`defaultAttributes`];

  if (fontSpec) {
    const fontValues = fontSpec?.values;
    if (fontValues[`fontSize`]) {
      richTextAttributes[`size`] = fontValues[`fontSize`];
    }

    if (fontValues[`textAlign`]) {
      richTextAttributes[`align`] = fontSpec.values[`textAlign`];
    }

    if (fontValues[`color`]) {
      richTextAttributes[`color`] = fontValues[`color`];
    }

    if (fontValues[`fontFamily`]) {
      richTextAttributes[`font`] = fontValues[`fontFamily`];
    }

    if (fontValues[`fontWeight`]) {
      richTextAttributes[`fontWeight`] = fontValues[`fontWeight`];

      if (fontValues[`fontWeight`] >= `700`) {
        richTextAttributes[`bold`] = true;
      }
    }

    if (fontValues[`textDecoration`]) {
      const val = fontValues[`textDecoration`];

      if (val === `underline`) {
        richTextAttributes[`underline`] = true;
      } else if (val === `line-through`) {
        richTextAttributes[`strike`] = true;
      } else if (val === `italic`) {
        richTextAttributes[`italic`] = true;
      }
    }

    if (fontValues[`letterSpacing`]) {
      richTextAttributes[`letterSpacing`] = fontValues[`letterSpacing`];
    }

    if (fontValues[`lineHeight`]) {
      richTextAttributes[`lineHeight`] = fontValues[`lineHeight`];
    }

    if (fontValues[`textTransform`]) {
      richTextAttributes[`transform`] = fontValues[`textTransform`];
    }
  }
  const shadowSpec = findSpecWithKey(oldCust, `shadow`);
  if (shadowSpec) {
    if (
      shadowSpec?.values?.[`enabled`] === true &&
      shadowSpec?.values?.[`value`]
    ) {
      richTextAttributes[`textShadow`] = shadowSpec?.values?.[`value`];
    }
  }

  currentSpec.values[`value`] = encodeURIComponent(
    `<p style="text-align: ${
      richTextAttributes[`align`]
    }"><span style="font-weight: ${
      richTextAttributes[`fontWeight`]
    }; font-family: ${richTextAttributes[`fontFamily`]}; font-size: ${
      richTextAttributes[`size`]
    }; color: ${richTextAttributes[`color`]}; letter-spacing: ${
      richTextAttributes[`letterSpacing`]
    }; text-shadow: ${richTextAttributes[`textShadow`]};">${
      oldSpec.values[`value`]
    }</span></p>`,
  );

  newSchema.customizations[custIdx].components[compIdx].specs[specIdx] = {
    ...currentSpec,
  };
}

function isUpgradeToRichText(
  oldSpec: CustomizationSpec,
  newSpec: CustomizationSpec,
) {
  return (
    oldSpec.type === `TEXT_EDIT` &&
    !oldSpec?.values?.[`isRichText`] &&
    newSpec.type === `TEXT_EDIT` &&
    newSpec.values[`isRichText`] === true
  );
}

function findOldWidgetSpec(
  oldSchema: CatalogWidgetProps,
  custKey: string,
  compKey: string,
  specKey: string,
): {
  spec: CustomizationSpec;
  component: Component;
  customization: Customization;
} {
  let spec: CustomizationSpec | undefined;
  let component: Component | undefined;
  let customization: Customization | undefined;

  oldSchema?.customizations?.forEach((cust) => {
    if (cust.key === custKey) {
      cust?.components?.forEach((comp) => {
        if (comp.key === compKey) {
          comp?.specs?.forEach((s) => {
            if (s.key === specKey) {
              spec = s;
              component = comp;
              customization = cust;
            }
          });
        }
      });
    }
  });

  return { spec, customization, component };
}

function findSpecWithKey(
  customization: Customization,
  key: string,
): CustomizationSpec {
  let out: CustomizationSpec | undefined;
  customization?.components?.forEach((comp) => {
    comp?.specs?.forEach((spec) => {
      if (spec.key === key) {
        out = spec;
      }
    });
  });
  return out;
}
