import React, { FC, useContext, useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Avatar } from '@/components/avatar';
import {
  AccountContext,
  AccountContextProps,
} from '@/features/account-context';
import { HeaderPopover } from '@/components/headerPopover';
import { BackToLive } from '@/components/BackToLive';
import { editorRoute, Pages } from '@/webapi/pages';
import { nav } from '@/utils/browser';
import { NewExperienceButton } from '@/features/dashboard/experiences/new-experience-button';
import { VisuallyLogo } from '@/features/strapi/components/logo';

const accountDomainTooltip = `account-domain-tooltip`;

export const Header: FC<{
  headerControls?: any;
  disabledAccount?: boolean;
  hideBackToLive?: boolean;
}> = ({ headerControls, disabledAccount, hideBackToLive }) => {
  const ctx = useContext(AccountContext);

  const onNewExperience = async () => {
    await nav(editorRoute());
  };

  const siteName = useMemo(
    () =>
      ctx?.account?.store?.domain
        ?.replace(`https://`, ``)
        ?.replace(`www.`, ``)
        ?.replace(`.myshopify.com`, ``),
    [ctx],
  );

  const shouldShowSiteNameTooltip = useMemo(
    () =>
      ctx?.account?.store?.domain?.replace(`https://`, ``)?.replace(`www.`, ``)
        .length > 20,
    [ctx],
  );

  const TooltipStyles = createGlobalStyle`
    .domain-tooltip {
      display: grid!important;
      justify-content: center;
      align-items: center;
      align-content: center;
      justify-items: center;
      grid-gap: 0.5rem;
      flex-direction: column;
      box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
      0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      border-radius: 1rem!important;
      font-family: "JetBrains Mono",serif;
      opacity: 1!important;
      padding: 1.2rem!important;
    }
  `;

  return (
    <Wrapper>
      <TooltipStyles />
      <section>
        <LogoSection onClick={() => nav(`/dashboard`)}>
          <VisuallyLogo small />
          <SiteName data-tip data-for={accountDomainTooltip}>
            {siteName || `Visually`}
          </SiteName>
          {shouldShowSiteNameTooltip && (
            <ReactTooltip
              id={accountDomainTooltip}
              place="bottom"
              backgroundColor="#fff"
              textColor="#000"
              className="domain-tooltip"
            >
              {ctx?.account?.store?.domain}
            </ReactTooltip>
          )}
        </LogoSection>
      </section>
      <section>
        <ControlsSection>{headerControls || <div />}</ControlsSection>
      </section>
      <section>
        <NewExperienceButton onClick={onNewExperience}>
          Create New Experience
        </NewExperienceButton>
        <AccountSection>
          <HeaderPopover
            disabledAccount={disabledAccount}
            onLogout={ctx.logout}
          >
            <Avatar
              size="3.9rem"
              profilePic={ctx?.account?.store?.profilePic}
              wrapperStyles={{
                background: `#ffffff`,
                boxShadow: `0 4px 6px 0 rgba(215,215,215,0.45)`,
                color: `#91A0AF`,
                fontWeight: `500`,
                fontSize: `1.3rem`,
                fontFamily: `Inter`,
              }}
            >
              {firstLetters(ctx)}
            </Avatar>
          </HeaderPopover>
        </AccountSection>
        {!hideBackToLive && (
          <BackToLive id="back_btn" onClick={() => nav(Pages.DASHBOARD)} />
        )}
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background: rgb(248, 249, 251);
  height: 70px;
  width: 100%;
  padding: 1rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;

  && > section {
    display: flex;

    :nth-child(1) {
      justify-content: flex-start;
      align-items: center;
    }

    :nth-child(2) {
      justify-content: center;
      align-items: center;
    }

    :nth-child(3) {
      display: grid;
      grid-template-columns: max-content max-content;
      justify-content: flex-end;
      align-items: center;
      grid-column-gap: 1.5rem;
    }
  }
`;

const LogoSection = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: default;
  }
`;

const ControlsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  * {
    font-family: 'Inter', serif !important;
    font-size: 1.2rem !important;
    font-weight: 800 !important;
  }
`;

const AccountSection = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  grid-gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  justify-items: flex-end;

  button {
    border: none;
    background: none;
  }
`;

const SiteName = styled.span`
  font-family: 'Inter', serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  max-width: 24rem;
  letter-spacing: -0.79px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function firstLetters(ctx: AccountContextProps) {
  const store = ctx?.account?.store;
  if (!store?.firstName) {
    return ``;
  }
  return `${store?.firstName?.[0]?.toUpperCase() || ``}${
    store?.lastName?.[0]?.toUpperCase() || ``
  }`;
}
