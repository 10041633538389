import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import {
  AllocationText,
  AllocationWrapper,
} from '@/features/editor/widgets/targeting/shared';
import { EditorContext } from '@/features/editor/context/editor-context';

export const AddVariantBtn: FC<{ foo?: string }> = () => {
  const editor = useContext(EditorContext);

  const { variants, isPostPurchase } = editor.experienceState.currentExperience;

  if (isPostPurchase || variants.length >= 6) {
    return null;
  }
  const ref = React.createRef<HTMLDivElement>();
  const onCreateFromScratch = () => {
    const { id: newId } = editor.experienceState.addEmptyVariant();
    console.log(newId);
  };

  return (
    <div ref={ref}>
      <VSpace value={2} />

      <Wrapper onClick={onCreateFromScratch}>
        <AllocationWrapper>
          <Text>Create another variant</Text>
        </AllocationWrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  :active {
    cursor: default;
    opacity: 1;
  }
`;

const Text = styled(AllocationText)`
  && {
    text-decoration: underline;
  }
`;
