import React, { useContext, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { useWidgetControls } from '@/features/editor/widgets/shared/use-widget-controls';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType, Role } from '@/utils/definitions';
import {
  PublishModal,
  usePublishModal,
} from '@/features/editor/use-publish-modal';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { AccountContext } from '@/features/account-context';
import {
  ScheduleModal,
  useScheduleModal,
} from '@/features/editor/use-schedule-modal';
import { InspectorTargetingView } from '@/features/editor/widgets/targeting/targeting-view';
import { handleInvalidAlloc } from '@/features/editor/widgets/targeting/shared';
import { FlexCol } from '@/components/flex';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';

export interface TargetingWidgetProps {
  disableThemesPicker?: boolean;
  disableAudiencePicker?: boolean;
  disablePlacementPicker?: boolean;
  disableDevicesPicker?: boolean;
  disableTriggerPicker?: boolean;
}

export function TargetingWidget({
  disableThemesPicker,
  disableAudiencePicker,
  disablePlacementPicker,
  disableDevicesPicker,
  disableTriggerPicker,
}: TargetingWidgetProps) {
  const {
    account: {
      store: { isTestStore, role },
    },
    showAppEmbedModalIfNeeded,
  } = useContext(AccountContext);
  const {
    experienceState,
    devicePreview,
    previewLoading,
    resyncMutations,
    inspectorNav: { gotoChangelog },
  } = useContext(EditorContext);
  const {
    editorState: { device },
  } = devicePreview;
  const { currentExperience } = experienceState;

  const isScheduled = useMemo(
    () => currentExperience?.schedule?.type !== undefined,
    [currentExperience?.schedule?.type],
  );

  const nextTitle = useMemo(
    () => (isScheduled ? `Schedule` : `Publish`),
    [isScheduled],
  );

  const { WidgetControls, controlsProps } = useWidgetControls(
    () => undefined,
    nextTitle,
  );

  const publishModalProps = usePublishModal(controlsProps.nextRef);
  const scheduleModalProps = useScheduleModal(
    controlsProps.nextRef,
    currentExperience,
  );

  controlsProps.onNextStep = () => {
    if (handleInvalidAlloc(currentExperience)) {
      return Promise.resolve();
    }
    if (isTestStore && role !== Role.SYSTEM) {
      toast(`Publishing experiences is not available for test stores.`, {
        theme: `colored`,
        type: `info`,
        className: css({ fontFamily: `JetBrains Mono, Serif` }),
      });
      return Promise.resolve();
    }
    if (showAppEmbedModalIfNeeded()) {
      return Promise.resolve();
    }
    if (isScheduled) {
      scheduleModalProps.show();
    } else {
      publishModalProps.show();
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (!previewLoading) {
      resyncMutations();
    }
  }, [previewLoading]);

  return (
    <InspectorWidgetLayout
      backCaption="< Back to changes"
      onBackClicked={gotoChangelog}
      title={
        <FlexCol>
          <span>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <EditorRenameButton
              nonEditPrefix={`Review "`}
              nonEditSuffix={`" before publish`}
            />
          </span>
          <DescriptionEditButton />
        </FlexCol>
      }
      progress={0}
      showCloseButton
      footer={
        <Wrapper device={device}>
          <WidgetControls {...controlsProps} />
        </Wrapper>
      }
    >
      <>
        <PublishModal {...publishModalProps} />
        <ScheduleModal {...scheduleModalProps} />
        <InspectorTargetingView
          disableThemePickerPicker={disableThemesPicker}
          disablePlacementPicker={disablePlacementPicker}
          disableAudiencePicker={disableAudiencePicker}
          disableTriggerPicker={disableTriggerPicker}
          disableDevicesPicker={disableDevicesPicker}
        />
      </>
    </InspectorWidgetLayout>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-right: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `0` : `20rem`};

  .checkbox_wrapper {
    height: auto;
  }
`;
