import styled from 'styled-components';

export const BackToLive = styled.span`
  position: absolute;
  top: 100px;
  left: 100px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #89959c;
  font-family: Inter, serif;
  cursor: pointer;
  transition: color 0.3s linear;
  :hover {
    color: #4f5359;
  }

  :after {
    content: '< Back to live';
    display: block;
  }
`;
