import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { GoSync } from 'react-icons/go';
import { EditorContext } from '@/features/editor/context/editor-context';
import {
  HAS_MOUNT_POINTS_SVG,
  MOUNT_POINT_SINGLE,
  NO_MOUNT_POINTS_SVG,
} from '@/features/editor/widgets/checkout-extensibility/mount-point-info-box/assets';
import {
  CheckoutNewComponent,
  LightweightMountPoint,
} from '@/features/editor/context/use-device-preview';
import { AddingMountPointsTutorial } from '@/features/editor/widgets/checkout-extensibility/mount-point-info-box/adding-mount-points-tutorial';

export function MountPointInfoBox() {
  const {
    checkoutSnapshotTimestamp,
    checkoutMountPoints,
    previewLoading,
    deviceNavigation: { previewUrl, reloadWithQueryParam },
    inspectorNav: { gotoChangelog },
    devicePreview: { checkoutNewComponent },
  } = useContext(EditorContext);

  const count = useMemo(
    () => checkoutMountPoints?.length || 0,
    [checkoutMountPoints],
  );

  const [isExpanded, setIsExpanded] = useState(count <= 0);

  const isVisible = useMemo(
    () => previewUrl === `shopify_checkout_extensibility` && !previewLoading,
    [previewUrl, previewLoading],
  );

  const onRefresh = () => {
    reloadWithQueryParam(`vslyBypassCheckoutCache`, `true`);
    gotoChangelog();
  };

  const onTitleClicked = () => {
    if (count > 0) {
      setIsExpanded((prev) => !prev);
    }
  };

  const lastSynced = useMemo(() => {
    if (checkoutSnapshotTimestamp <= 0) {
      return `Unknown`;
    }
    const secondsAgo = new Date().getTime() / 1000 - checkoutSnapshotTimestamp;
    if (secondsAgo < 60) {
      return `Now`;
    }
    if (secondsAgo < 3600) {
      return `${Math.floor(secondsAgo / 60)}m ago`;
    }
    if (secondsAgo < 86400) {
      return `${Math.floor(secondsAgo / 3600)}h ago`;
    }
    return `${Math.floor(secondsAgo / 86400)}d ago`;
  }, [checkoutSnapshotTimestamp]);

  const onAddNewComponent = (mp: LightweightMountPoint) => {
    const sectionId = `_loomi_addon_${new Date().getTime()}`;
    const msg = {
      mountPointSelector: mp.mountpointSelector,
      placement: `root`,
      sectionId,
    } as CheckoutNewComponent;
    checkoutNewComponent(msg);
  };

  return isVisible ? (
    <Wrapper count={count}>
      <MainRow>
        {count <= 0 ? <NO_MOUNT_POINTS_SVG /> : <HAS_MOUNT_POINTS_SVG />}
        <InnerLayout>
          <InnerRow>
            <Title onClick={onTitleClicked}>
              <span className={count > 0 ? `expandable` : ``}>
                {count || 0} extension point
                {count !== 1 ? `s` : ``}
              </span>
              {` `}
              found
            </Title>
            <Button onClick={onRefresh}>
              <GoSync size={15} />
              REFRESH
            </Button>
          </InnerRow>
          <InnerRow>
            <Subtitle>Last sync: {lastSynced}</Subtitle>
            <Subtitle style={{ fontSize: `1rem` }}>
              Can&apos;t see new points? Refresh
            </Subtitle>
          </InnerRow>
        </InnerLayout>
      </MainRow>

      {isExpanded && (
        <>
          <Separator />
          <SubSection>
            {count > 0 && (
              <List>
                {checkoutMountPoints?.map((mp) => (
                  <MountPointRow key={mp.mountpointSelector}>
                    <MOUNT_POINT_SINGLE />
                    <Title>{mp.mountpointSelector}</Title>
                    <Button onClick={() => onAddNewComponent(mp)}>ADD</Button>
                  </MountPointRow>
                ))}
              </List>
            )}
            {count <= 0 && <AddingMountPointsTutorial />}
          </SubSection>
        </>
      )}
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #f6f6f6;
  padding: ${(p) => (p.count > 0 ? `2rem` : `2.5rem`)};
  border-radius: 1.8rem;

  font-family: Inter, serif;

  margin-bottom: 2rem;
`;

const MainRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  align-items: center;
`;

const InnerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 2rem;
`;

const InnerLayout = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  justify-content: center;
`;

const MountPointRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 2rem;
  align-items: center;
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  button {
    align-self: unset;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: #e7e7e7;
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 1.4rem;

  span.expandable {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
  }
`;

const Subtitle = styled.span`
  color: #8d99ae;
  font-size: 1.1rem;
  font-weight: 400;
`;

const Button = styled.button`
  appearance: none;
  border: none;
  width: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 6px 7px 0 rgba(151, 210, 206, 0.22);
  border-radius: 0.6rem;
  color: #798794;
  background: #ffffff;
  font-weight: 500;
  font-family: Inter, serif;

  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s ease-in-out;

  && > svg {
    margin-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;
