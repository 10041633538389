import React from 'react';
import styled from 'styled-components';

export function AddingMountPointsTutorial() {
  return (
    <Wrapper>
      <span>
        To add additional blocks to the checkout page in Shopify, we first need
        to add extension points in the Shopify Checkout Editor within the theme.
      </span>
      <div
        style={{
          position: `relative`,
          paddingTop: `56.25%`,
          marginBottom: `1rem`,
        }}
      >
        <Video
          src="https://iframe.mediadelivery.net/embed/173074/8482477f-7e3c-413d-8c74-115cf97d8b98?autoplay=false&loop=false&muted=false&preload=true&responsive=true"
          loading="lazy"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          // @ts-ignore
          allowFullScreen="true"
        />
      </div>
      <Instruction>
        <Step>1</Step>
        <span>
          Go to your Shopify account, navigate to <b>Settings &gt; Checkout</b>,
          and click <b>Customize</b> on your live checkout profile.
        </span>
      </Instruction>
      <Instruction>
        <Step>2</Step>
        <span>
          Add as many extension points as possible. The more you add, the more
          sections will appear in the checkout, allowing you to add blocks via
          the visual editor.
        </span>
      </Instruction>
      <Instruction>
        <Step>3</Step>
        <span>
          Ensure each extension point has a <b>unique name</b> for proper
          functionality. When you&apos;re done, <b>click Save</b>.
        </span>
      </Instruction>
      <Instruction>
        <Step>4</Step>
        <span>
          Click the <b>Refresh</b> button to view the extension points in the
          editor on the left. Click on them to add a new block
        </span>
      </Instruction>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  font-family: Inter, serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: black;

  line-height: 1.5;
`;

const Instruction = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  color: #60686e;
  align-items: center;
`;

const Step = styled.span`
  border-radius: 50%;
  background: #ebebeb;
  font-size: 1.1rem;
  color: #798794;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 3.5rem;
  width: 3.5rem;
`;

const Video = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
`;
