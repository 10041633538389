/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useContext, useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { RiGlobalLine } from 'react-icons/ri';
import { FaCogs } from 'react-icons/fa';
import { IoIosLogOut } from 'react-icons/io';
import { MdOutlineFileCopy, MdOutlineHelpCenter } from 'react-icons/md';
import { HiOutlinePencil } from 'react-icons/hi2';
import { AiOutlineTeam } from 'react-icons/ai';
import { isAdmin } from '@/utils/rbac-utils';
import { Pages } from '@/webapi/pages';
import {
  AccountContext,
  AccountContextProps,
  useFeatureBit,
} from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { nav } from '@/utils/browser';
import { VSpace } from '@/components/spacing';

export const HeaderPopover: FC<{
  onLogout: () => void;
  disabledAccount?: boolean;
}> = ({ children, onLogout, disabledAccount }) => {
  const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const ctx = useContext(AccountContext);
  const store = ctx?.account?.store;
  const isAppEmbedRevertEnabled = useFeatureBit(FeatureBit.APP_EMBED_REVERT);

  return (
    <Popover
      containerStyle={{ zIndex: `4` }}
      isOpen={disabledAccount ? false : isPopoverOpen}
      positions={[`bottom`]}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      ref={clickMeButtonRef}
      content={({ position, childRect, popoverRect }) => (
        <ArrowWrapper>
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
            arrowStyle={{ opacity: 0.7 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <Wrapper>
              <UpperPart>
                <UserName>
                  {capitalize(store?.firstName)} {capitalize(store?.lastName)}
                  <span
                    className="edit-btn"
                    onClick={() => nav(Pages.ACCOUNT_SETTINGS)}
                  >
                    <HiOutlinePencil size={15} />
                  </span>
                </UserName>
                <VSpace value={0.01} />
                <Email>{store?.email}</Email>
                <VSpace value={0.01} />
                <MenuButton
                  style={{ gridTemplateColumns: `1fr` }}
                  onClick={() => nav(Pages.ACCOUNTS)}
                >
                  <span>Connected Stores</span>
                </MenuButton>
              </UpperPart>
              <BottomPart>
                <StoreDomain>{takeDomain(ctx)}</StoreDomain>
                <MenuButton onClick={() => nav(Pages.SHOP_SETTINGS)}>
                  <AccountAvatar>{upperFirstDomainLetter(ctx)}</AccountAvatar>
                  <span>Account Settings</span>
                </MenuButton>
                {isAdmin(ctx?.account?.store?.role) && (
                  <MenuButton onClick={() => nav(Pages.TEAMMATES)}>
                    <AiOutlineTeam size={20} />
                    <span>My Teammates</span>
                  </MenuButton>
                )}
                <MenuButton onClick={() => nav(Pages.GLOBAL_RULES)}>
                  <RiGlobalLine size={20} />
                  <span>Global Rules</span>
                </MenuButton>
                {isAppEmbedRevertEnabled && (
                  <MenuButton onClick={() => nav(Pages.THEMES)}>
                    <MdOutlineFileCopy size={20} />
                    <span>Theme Settings</span>
                  </MenuButton>
                )}
                <MenuButton onClick={() => nav(Pages.INTEGRATIONS)}>
                  <FaCogs size={20} />
                  <span>Integrations</span>
                </MenuButton>
                <MenuButton
                  onClick={() =>
                    window.open(`https://help.visually.io`, `support`)
                  }
                >
                  <MdOutlineHelpCenter size={20} />
                  <span>Help Center</span>
                </MenuButton>
                <MenuButton onClick={onLogout}>
                  <IoIosLogOut size={20} />
                  <span>Logout</span>
                </MenuButton>
              </BottomPart>
            </Wrapper>
          </ArrowContainer>
        </ArrowWrapper>
      )}
    >
      <button
        data-testid="popover-btn"
        type="button"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        {children}
      </button>
    </Popover>
  );
};

const ArrowWrapper = styled.div`
  .popover-arrow {
    z-index: 10;
    filter: drop-shadow(0 0 0 black);
    top: 1px !important;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fafbfd;
  font-family: Inter, serif;

  min-width: 30rem;
  margin-left: -24rem;
  padding-bottom: 2rem;

  border-radius: 0.8rem;
  box-shadow: 0 0.6rem 0.7rem 0 rgba(151, 210, 206, 0.22),
    0 1px 1px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.4px #d5d9dc;

  user-select: none;

  && * {
    user-select: none;
  }

  animation: Appear 0.3s ease-out;

  @keyframes Appear {
    from {
      opacity: 0;
      max-height: 0;
    }
    to {
      opacity: 1;
      max-height: 60rem;
    }
  }
`;

const UpperPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  padding: 2rem 3rem;

  background-color: white;
`;

const UserName = styled.span`
  font-size: 1.8rem;
  font-weight: 600;

  .edit-btn {
    position: relative;
    margin-left: 0.5rem;
    cursor: pointer;

    svg {
      position: absolute;
      top: 0.3rem;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

const Email = styled.span`
  font-size: 1.2rem;
`;

const MenuButton = styled.button`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  gap: 1rem;

  padding: 0;
  margin: 0;

  font-size: 1.4rem;

  && > span {
    text-decoration: underline;
  }

  transition: opacity 0.2s ease-out;
  cursor: pointer;

  &&:hover {
    opacity: 0.7;
  }

  &&:active {
    && > span {
      text-decoration: none;
    }
  }
`;

const BottomPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  padding: 2rem 3rem;
`;

const StoreDomain = styled.span`
  font-size: 1.3rem;
  color: #a6afb8;
`;

const AccountAvatar = styled.i`
  height: 2.3rem;
  width: 2.3rem;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: 1px solid black;
  border-radius: 100%;
  font-size: 1rem;

  text-decoration: none;
  font-style: normal;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 100%;
    background-color: rgb(99 197 119);

    bottom: -0.2rem;
    left: -0.1rem;
  }
`;

function takeDomain(ctx: AccountContextProps) {
  return ctx.account?.store?.domain
    ?.replace(`https://`, ``)
    ?.replace(`www.`, ``);
}

function capitalize(str: string) {
  if (str) {
    return str[0].toUpperCase() + str.slice(1);
  }
  return ``;
}

function upperFirstDomainLetter(ctx: AccountContextProps) {
  return takeDomain(ctx)?.[0]?.toUpperCase() || ``;
}
