import React, { useCallback, useContext, useMemo, useState } from 'react';
import { HiOutlinePencil } from 'react-icons/hi2';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import ReactTooltip from 'react-tooltip';
import { HiEye } from 'react-icons/hi';
import { toast } from 'react-toastify';
import {
  Experience,
  ExperienceInfoResponse,
  ExperienceStatus,
  UNNAMED_EXPERIENCE,
} from '@/webapi/use-experience-api';
import { formatEnum } from '@/utils/types';
import { VSpace } from '@/components/spacing';
import { Metric } from '@/components/metric';
import {
  getFormattedMetric,
  getMetric,
  getRelativeMetric,
  getStatusForScheduledExperien,
  nFormatter,
  parseScheduledTime,
} from '@/utils/experience-utils';
import { MetricKind } from '@/webapi/models';
import { DotLabel, getChipColor } from '@/components/dot-label';
import { Flexbox } from '@/components/flex';
import { formatToLocalDate, maybe } from '@/features/details/utils';
import { breakpoints } from '@/components/responsive';
import { ExperienceTileOptionsPopover } from '@/features/dashboard/experiences/options-popover';
import { shouldHideListSummary } from '@/utils/data_freshness';
import { CONTROL } from '@/features/details/shared';
import { conditional } from '@/utils/conditional';
import { formatter, isPercent } from '@/features/details/charts/chart';
import { nanGuard } from '@/features/details/summary/util';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { ExperienceInfoShortLine } from '@/features/shared/experience-info-short-line';
import { toPlainText } from '@/utils/rich-text-utils';
import { LabelsStrip } from '@/features/shared/labels-strip';
import Portal from '@/components/portal';
import { useOptionsModals } from '@/features/dashboard/experiences/use-options-modals';
import { nav } from '@/utils/browser';
import { editorRoute } from '@/webapi/pages';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { PermanentPreviewModal } from '@/features/dashboard/experiences/preview-modal';
import { ReBalanceChip } from '@/features/details/rebalance-chip/allocation-chip';
import {
  isThemeTestPublishNotAllowed,
  showThemeTestWarningToast,
} from '@/features/editor';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import playImage from '../../../assets/play.svg';
// @ts-ignore
import duplicateImage from '../../../assets/duplicate.svg';
// @ts-ignore
import editImage from '../../../assets/edit.svg';
import { Role } from '@/utils/definitions';
/* eslint-enable @typescript-eslint/ban-ts-comment */

export function ExperienceTile({
  updateRow,
  experience,
  onTileClick,
  expAllocationChange,
  togglePopover,
}: {
  updateRow: (exp: Experience) => void;
  experience: Experience;
  onTileClick: (Experience) => void;
  expAllocationChange: string;
  togglePopover: (id: string) => void;
}) {
  const onWrapperClick = useCallback(
    (ev) => {
      if (ev.target.closest(`.popover-arrow-container`)) {
        return;
      }
      if (
        !(
          ev?.target?.className?.includes(`Backdrop`) &&
          ev?.target?.className?.includes(`shared-element`)
        )
      ) {
        onTileClick(experience);
      }
    },
    [experience],
  );

  const expStatus = getStatusForScheduledExperien(experience);
  const chipColor = getChipColor(expStatus);
  const chipText = formatEnum(expStatus).toLocaleLowerCase();
  const [isHoveringFullTile, setIsHoveringFullTile] = useState(false);
  const [isHoveringRightSide, setIsHoveringRightSide] = useState(false);
  const [isHoveringLeftSide, setIsHoveringLeftSide] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const showPopover = (ev) => {
    ev.stopPropagation();
    setIsPopoverOpen(true);
  };

  const shouldOnlyShowOnHover = useMemo(
    () =>
      !!toPlainText(experience.description) ||
      experience.beforeImages?.length > 0 ||
      experience.afterImages?.length > 0,
    [experience],
  );
  const upliftsEnabled = useFeatureBit(FeatureBit.UPLIFT_METRICS);

  const onExperienceInfoChanged = (info: ExperienceInfoResponse) => {
    const clone = JSON.parse(JSON.stringify(experience)) as Experience;
    clone.name = info.name;
    clone.description = info.description;
    clone.labels = info.labels?.filter((l) => l.selected)?.map((l) => l.label);
    clone.beforeImages = info.beforeImages;
    clone.afterImages = info.afterImages;
    updateRow(clone);
  };

  const onEdit = async (ev) => {
    ev.stopPropagation();
    await nav(editorRoute(experience.id));
  };

  return (
    <Wrapper
      onClick={onWrapperClick}
      onMouseEnter={() => setIsHoveringFullTile(true)}
      onMouseLeave={() => setIsHoveringFullTile(false)}
    >
      <LeftPart
        onMouseEnter={() => setIsHoveringLeftSide(true)}
        onMouseLeave={() => setIsHoveringLeftSide(false)}
      >
        <StateChip bgColor={chipColor}>{chipText}</StateChip>
        <ExperienceDetails>
          <Title id={`exp-title-${experience.id}`}>{experience.name}</Title>
          {(shouldOnlyShowOnHover || isHoveringLeftSide) && (
            <InfoWrapper noMargin={!shouldOnlyShowOnHover}>
              <ExperienceInfoShortLine
                maxWidth="40rem"
                onExperienceInfoChanged={onExperienceInfoChanged}
                currentExperience={experience}
                nonePlaceholder={
                  !shouldOnlyShowOnHover ? (
                    <Portal selector={`#exp-title-${experience.id}`}>
                      <ReactTooltip
                        id={`edit-${experience.id}`}
                        place="bottom"
                        backgroundColor="#fff"
                        textColor="#000"
                        className="exp-short-line-tooltip"
                      >
                        Click to edit experience details
                      </ReactTooltip>
                      <AddMoreInfo data-tip data-for={`edit-${experience.id}`}>
                        <HiOutlinePencil />
                      </AddMoreInfo>
                    </Portal>
                  ) : (
                    `+ Add more info`
                  )
                }
                hideEditButton={!isHoveringLeftSide}
              />
            </InfoWrapper>
          )}
          {experience.labels?.length > 0 && (
            <>
              <VSpace value={1.2} />
              <LabelsStrip labels={experience.labels} />
            </>
          )}
          <VSpace value={1.2} />
          <DotLabel dotColor={chipColor}>
            {getDateText(experience, upliftsEnabled)}
          </DotLabel>
        </ExperienceDetails>
      </LeftPart>
      <Divider />
      <RightPart
        onMouseEnter={() => setIsHoveringRightSide(true)}
        onMouseLeave={() => setIsHoveringRightSide(false)}
      >
        <ExperienceStats
          experience={experience}
          isHovering={isHoveringFullTile}
          updateRow={updateRow}
          setIsHovering={setIsHoveringRightSide}
          expAllocationChange={expAllocationChange}
          togglePopover={togglePopover}
        />
        <IconWrapper>
          {experience.status === ExperienceStatus.PUBLISHED && (
            <PencilIcon
              onClick={onEdit}
              isHoveringRightSide={isHoveringRightSide}
            >
              <HiOutlinePencil />
            </PencilIcon>
          )}

          <Icon
            onClick={showPopover}
            style={{ cursor: `context-menu`, padding: `0 2rem 0 0` }}
          >
            <ExperienceTileOptionsPopover
              experience={experience}
              setIsVisible={setIsPopoverOpen}
              isVisible={isPopoverOpen}
            >
              <div>
                <StaticImage
                  height={20}
                  placeholder="none"
                  loading="eager"
                  src="../../../assets/three-dots.svg"
                  alt="close"
                />
              </div>
            </ExperienceTileOptionsPopover>
          </Icon>
        </IconWrapper>
      </RightPart>
    </Wrapper>
  );
}

function getSessionTypeName(sessionType: string | undefined) {
  if (sessionType === `users`) {
    return `Users`;
  }
  return `Sessions`;
}

function ExperienceOptions({
  experience,
  isHovering = false,
}: {
  experience: Experience;
  isHovering?: boolean;
}) {
  const {
    clone: { CloneModal, cloneModalProps, cloneRef, showCloneModal },
    publish: { PublishModal, publishModalProps, publishRef, showPublishModal },
  } = useOptionsModals({ experience });

  const {
    props: previewProps,
    show: showPreviewModal,
    hide: hidePreviewModal,
    fromRef: previewRef,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () =>
      centered(17.5 + Math.max(experience.variants.length - 2, 0) * 4.35, 42),
  );

  const {
    account: {
      store: { isTestStore, role },
    },
    showAppEmbedModalIfNeeded,
  } = useContext(AccountContext);

  const onEdit = async (ev) => {
    ev.stopPropagation();
    await nav(editorRoute(experience.id));
  };

  const onPublish = async (ev) => {
    ev.stopPropagation();
    const response = await isThemeTestPublishNotAllowed(experience);
    if (response?.value) {
      showThemeTestWarningToast(response);
    } else if (isTestStore && role !== Role.SYSTEM) {
      toast(`Publishing experiences is not available for test stores.`, {
        theme: `colored`,
        type: `info`,
        className: css({ fontFamily: `JetBrains Mono, Serif` }),
      });
    } else if (!showAppEmbedModalIfNeeded()) {
      showPublishModal();
    }
  };

  const onDuplicate = async (ev) => {
    ev.stopPropagation();
    showCloneModal();
  };

  const onPreview = (ev) => {
    ev.stopPropagation();
    if (!showAppEmbedModalIfNeeded()) {
      showPreviewModal();
    }
  };

  return (
    <OptionsWrapper isHovering={isHovering}>
      <CloneModal {...cloneModalProps} />
      <PublishModal {...publishModalProps} />
      {previewProps.isVisible && (
        <PermanentPreviewModal
          overlayProps={previewProps}
          hide={hidePreviewModal}
          experienceId={experience.id}
          themeId={experience.themeId}
          mainThemeId={experience.mainThemeId}
        />
      )}
      <Row onClick={onEdit}>
        <img
          src={editImage}
          alt="menu"
          height={13}
          placeholder="none"
          loading="eager"
          style={{
            opacity: 0.8,
          }}
        />
        Edit
      </Row>
      {experience.name !== UNNAMED_EXPERIENCE && (
        <>
          <Row onClick={onDuplicate} ref={cloneRef}>
            <img
              src={duplicateImage}
              alt="menu"
              height={13}
              placeholder="none"
              loading="eager"
              style={{
                opacity: 0.8,
              }}
            />
            Duplicate
          </Row>
          {!experience.isPostPurchase && (
            <Row onClick={onPreview} ref={previewRef}>
              <HiEye size="1.5rem" />
              Preview
            </Row>
          )}
          {[ExperienceStatus.DRAFT, ExperienceStatus.PAUSED].includes(
            experience.status,
          ) && (
            <Row onClick={onPublish} ref={publishRef}>
              <img
                src={playImage}
                alt="menu"
                height={13}
                placeholder="none"
                loading="eager"
                style={{
                  opacity: 0.8,
                }}
              />
              Start
            </Row>
          )}
        </>
      )}
    </OptionsWrapper>
  );
}

function ExperienceStats({
  experience,
  isHovering = false,
  updateRow,
  setIsHovering,
  togglePopover,
  expAllocationChange,
}: {
  experience: Experience;
  isHovering?: boolean;
  updateRow?: (exp: Experience) => void;
  setIsHovering?: (isHovering: boolean) => void;
  togglePopover: (id: string) => void;
  expAllocationChange: string;
}) {
  const primaryGoal = experience?.primaryGoal || experience?.goals?.[0];
  const goalMetric = getRelativeMetric(
    {
      ...experience,
      variants: experience.variants.filter((v) => v.publishedChance > 0),
    },
    primaryGoal,
  );

  const variation100 = maybe(() =>
    experience.variants.find((v) => v.publishedChance === 100),
  );
  if (variation100) {
    const value = getMetric(experience, primaryGoal, variation100.name);
    goalMetric.formatted = getFormattedMetric(experience, primaryGoal);
    if (
      [
        MetricKind.AVG_ORDER_VALUE,
        MetricKind.CHECKOUT_RATE,
        MetricKind.CONVERSION_RATE,
        MetricKind.PRODUCT_DETAILS_VIEWS_RATE,
        MetricKind.ADD_TO_CART_RATE,
        MetricKind.SIGNUPS_RATE,
        MetricKind.SUBSCRIPTION_RATE,
        MetricKind.CTR,
      ].includes(primaryGoal)
    ) {
      goalMetric.formatted = `${value.value > 0 ? `+` : ``}${(
        value.value * 100
      ).toFixed(2)}%`;
    }
    goalMetric.raw = getMetric(experience, primaryGoal).value;
    goalMetric.color = `#5b6971`;
  }
  const status = experience?.status;

  let metricName = formatEnum(primaryGoal);
  const idx = metricName.indexOf(`(`);
  metricName = metricName?.substring(0, idx > 0 ? idx : metricName.length);

  const formattedAbsoluteMetricValue = useMemo(() => {
    let metric = 0;
    if (isPercent(primaryGoal) && primaryGoal !== MetricKind.ADD_TO_CART_RATE) {
      metric =
        getMetric(experience, primaryGoal, variation100?.name).value * 100;
    } else {
      metric = getMetric(experience, primaryGoal, variation100?.name).value;
    }
    return nanGuard(formatter(primaryGoal)(metric));
  }, [experience, primaryGoal, variation100]);

  const expSessions = maybe(
    () =>
      nFormatter(
        experience.variants
          .map(
            (v) =>
              getMetric(experience, MetricKind.SESSIONS, v.name).value || 0,
          )
          .reduce((a, b) => a + b, 0),
        2,
      ),
    0,
  );
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {status === ExperienceStatus.PUBLISHED &&
      shouldHideListSummary(experience) ? (
        <ShouldHideWrapper withAllocate={shouldShowDetails(experience)}>
          {shouldShowDetails(experience) && (
            <MetricWrapper
              onMouseEnter={() => setIsHovering(false)}
              onMouseLeave={() => setIsHovering(true)}
            >
              <ReBalanceChip
                experience={experience}
                setExperience={updateRow}
                isOpenFromParent={expAllocationChange === experience.id}
                togglePopover={(expId) => togglePopover(expId)}
              >
                <Metric
                  name="Allocation"
                  value={formatAllocation(experience)}
                  showEdit
                />
              </ReBalanceChip>
            </MetricWrapper>
          )}
          <NoDataWrapper>
            <span>Waiting for data...</span>
            <span>We collect and process the data for you</span>
          </NoDataWrapper>
        </ShouldHideWrapper>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!shouldShowDetails(experience) ? (
            <ExperienceOptions
              experience={experience}
              isHovering={isHovering}
            />
          ) : (
            <StatsWrapper
              style={{
                opacity:
                  experience.status === ExperienceStatus.DRAFT ? `0.4` : `1`,
              }}
            >
              {shouldShowDetails(experience) ? (
                <MetricWrapper
                  onMouseEnter={() => setIsHovering(false)}
                  onMouseLeave={() => setIsHovering(true)}
                >
                  {experience.status === ExperienceStatus.PUBLISHED ? (
                    <ReBalanceChip
                      experience={experience}
                      setExperience={updateRow}
                      isOpenFromParent={expAllocationChange === experience.id}
                      togglePopover={(expId) => togglePopover(expId)}
                    >
                      <Metric
                        name="Allocation"
                        value={formatAllocation(experience)}
                        showEdit
                      />
                    </ReBalanceChip>
                  ) : (
                    <Metric
                      name="Allocation"
                      value={formatAllocation(experience)}
                    />
                  )}
                </MetricWrapper>
              ) : (
                <div />
              )}
              {shouldShowDetails(experience) ? (
                <Metric
                  name={getSessionTypeName(experience?.sessionType)}
                  value={expSessions}
                />
              ) : (
                <div />
              )}
              {conditional(
                <div />,

                shouldShowRelativeGoalMetric(experience),
                <Metric
                  color={goalMetric.color}
                  name={metricName}
                  value={goalMetric.formatted}
                />,

                shouldShowAbsoluteGoalMetric(experience),
                <Metric
                  color="#899f9c"
                  name={metricName}
                  value={formattedAbsoluteMetricValue}
                />,

                shouldShowRelativeGoalMetricBetweenVariants(experience),
                <Metric
                  color={goalMetric.color}
                  name={metricName}
                  value={goalMetric.formatted}
                  subtitle={`for ${goalMetric.best}`}
                />,
              )}
              <div />
            </StatsWrapper>
          )}
        </>
      )}
    </>
  );
}

function noVariantsWith100PercentAllocation(experience: Experience) {
  return maybe(
    () => !experience.variants.find((v) => v.publishedChance === 100),
  );
}

function controlHasAllocation(experience: Experience) {
  return maybe(
    () => !!experience.variants.find((v) => v.name === CONTROL && v.chance > 0),
  );
}

function shouldShowRelativeGoalMetric(experience: Experience): boolean {
  return (
    shouldShowDetails(experience) &&
    noVariantsWith100PercentAllocation(experience) &&
    controlHasAllocation(experience)
  );
}

function shouldShowAbsoluteGoalMetric(experience: Experience): boolean {
  return (
    shouldShowDetails(experience) &&
    !noVariantsWith100PercentAllocation(experience)
  );
}

function shouldShowRelativeGoalMetricBetweenVariants(
  experience: Experience,
): boolean {
  return (
    shouldShowDetails(experience) &&
    noVariantsWith100PercentAllocation(experience) &&
    experience.variants.length > 2
  );
}

function shouldShowDetails(experience: Experience): boolean {
  const isShowArchivedStats = useFeatureBit(
    FeatureBit.DASHBOARD_SHOW_ARCHIVED_STATS,
  );

  return (
    [ExperienceStatus.PUBLISHED].includes(experience?.status) ||
    (isShowArchivedStats &&
      ([ExperienceStatus.ARCHIVED].includes(experience?.status) ||
        [ExperienceStatus.PAUSED].includes(experience?.status)))
  );
}

function formatAllocation(experience: Experience): string {
  const runningVariants = experience.variants.filter(
    (v) => v.publishedChance > 0,
  );

  let control = maybe(
    () => runningVariants.find((v) => v.name === CONTROL).publishedChance,
    0,
  );

  if (control === 0 && runningVariants.length === 2) {
    // multi variant no control, 2 variants
    control = runningVariants[0].publishedChance;
  } else if (control === 0 && runningVariants.length > 2) {
    return `multi`;
  }

  const variant1 = 100 - control;

  if (control === 0) {
    return `100%`;
  }
  return `${variant1} / ${control}`;
}

const Wrapper = styled.div`
  background: white;
  border: 1px solid #f1f3f3;
  border-radius: 1.5rem;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.09), 0 2px 8px 0 rgb(7, 6, 6, 0.04);
  width: 87%;
  ${breakpoints.up(1500)} {
    width: 77%;
  }
  min-height: 11rem;
  max-height: 16rem;
  padding: 2rem 4rem;

  font-family: 'Inter', serif;

  display: grid;
  grid-template-columns: 1fr 0.15rem 1fr;
  align-items: center;

  cursor: pointer;
  transition: background-color 0.5s ease-out;

  user-select: none;

  :hover {
    background-color: #fafafa;
  }
`;

const AddMoreInfo = styled.span`
  color: #93989b;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0.5rem;
  align-self: center;
  margin-left: 1rem;
  cursor: context-menu;

  &&:hover {
    opacity: 0.8;
  }

  &&:hover {
    opacity: 0.6;
  }
`;

const LeftPart = styled.div`
  display: grid;
  grid-template-columns: minmax(5rem, 7.4rem) 1fr;
  grid-column-gap: 2rem;
  align-items: center;
  text-align: left;
`;

const RightPart = styled.div`
  padding-left: 2rem;
  display: grid;
  grid-template-columns: 8fr 1fr;
`;

const Divider = styled.div`
  background: #f1f3f4;
  height: 50%;
`;

const StateChip = styled.span`
  text-transform: capitalize;
  width: auto;
  padding: 0.4rem 1rem;
  background-color: ${(p: any) => p.bgColor};
  color: white;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`;

const ExperienceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.span`
  position: relative;
  font-size: 1.6rem;
  color: #333333;
  font-weight: 500;
  padding-right: 2rem;
`;

const StatsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 5fr 5fr 5fr 1fr;
  grid-column-gap: 1rem;
`;

const InfoWrapper = styled.div`
  margin-top: ${(p) => (p.noMargin ? `0` : `1rem`)};
  display: flex;
  max-width: 70%;
`;

const ShouldHideWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props: { withAllocate: boolean }) =>
    props.withAllocate ? `1fr 3fr` : `1fr`};
  div:last-child {
    padding-left: ${(props: { withAllocate: boolean }) =>
      props.withAllocate ? `1rem` : `0rem`};
  }
`;

const NoDataWrapper = styled(Flexbox)`
  && {
    justify-content: center;
    font-family: Inter, serif;
    line-height: 1.6;
    color: #898989;
    width: 100%;
    height: 100%;

    span:first-child {
      font-size: 1.4rem;
      font-weight: 500;
      text-align: left;
    }

    span:last-child {
      font-size: 1.2rem;
      font-weight: 300;
      text-align: left;
    }
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const OptionsWrapper = styled.div<{ isHovering: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  opacity: ${({ isHovering }) => (isHovering ? 1 : 0)};
  visibility: ${({ isHovering }) => (isHovering ? `visible` : `hidden`)};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const Row = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1.2rem 1fr;
  grid-gap: 1.5rem;
  border-radius: 1rem !important;
  padding: 1.5rem 2.3rem;
  align-items: center;
  font-size: 1.2rem;
  color: gray;

  img {
    object-fit: contain !important;
  }

  transition: background-color 0.3s linear;

  :hover {
    background: #fafafa;
    color: black;
    transition: color 0.3s ease-in-out;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
`;

const PencilIcon = styled(Icon)`
  transition: opacity 0.3s ease;
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  opacity: ${({ isHoveringRightSide }) => (isHoveringRightSide ? 0.5 : 0)};
`;

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function getDateText(exp: Experience, upliftsEnabled: boolean) {
  const status = getStatusForScheduledExperien(exp);
  const is100 = exp?.variants?.find((v) => v.chance === 100);
  const uplift = exp?.uplift;
  if (!!uplift && is100 && upliftsEnabled) {
    let lift = `${(uplift?.cr || 0)?.toFixed(2)}% in CR`;
    if (exp?.primaryGoal === MetricKind.PER_SESSION_VALUE) {
      lift = `${(uplift?.psv || 0)?.toFixed(2)}% in PSV`;
    }
    if (exp?.primaryGoal === MetricKind.AVG_ORDER_VALUE) {
      lift = `${(uplift?.psv || 0)?.toFixed(2)}% in AOV`;
    }
    return (
      <span>
        Moved to 100% on:
        {formatToLocalDate(exp?.metricsStartAt || exp?.publishedAt)}. Uplift:
        {lift}
      </span>
    );
  }
  if (status === ExperienceStatus.DRAFT)
    return `last edited: ${formatToLocalDate(exp?.updatedAt)}`;
  if (status === ExperienceStatus.ARCHIVED)
    return `end date: ${formatToLocalDate(exp?.endDate || exp?.archivedAt)}`;
  if (status === ExperienceStatus.SCHEDULED)
    return `scheduled ${parseScheduledTime(exp?.schedule)}`;
  return `start date: ${formatToLocalDate(
    exp?.metricsStartAt || exp?.publishedAt,
  )}`;
}
